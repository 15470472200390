// App.js
import React, { useState, useEffect } from 'react';
import { Text, View } from "react-native-web";

import { styles, updateDimensions } from "../styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderDark from '../headerDark';
import { chromeWebstoreAddyURL } from '../globalVariables';
import Footer from '../footer';
import VideoPlayer from '../components/video';

var mainImageAlign = "center";

var composeDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Fcompose_demo_simple.mp4?alt=media&token=ea96c05b-9a8e-4bd2-9397-4536f93b431c";
var followUpDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ffollow_up_demo_simple.mp4?alt=media&token=cb5ca2e6-2558-4a8f-8b8f-63a80a6e2d2e";
var trainingDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ftraining_demo_simple.mp4?alt=media&token=2d5eea3c-4e5b-4577-bb68-d19b6a93f329";
var replyDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Freply_demo_simple.mp4?alt=media&token=be11ab20-39ae-497e-9d32-f65fc450b71b";
let elementStyles = styles;
updateDimensions();

const TryEllieAlternative = () => {

    return (

        <div className="app bg-dark text-white">

            <HeaderDark />

            <div className="PrimarySectionContainer" style={{
                backgroundColor: "rgb(32, 33, 36)",
                paddingBottom: "70px",
                // minHeight: "100vh"
            }}>


                {/* First background */}
                <View style={{ paddingTop: 40, paddingLeft: styles.basic.blueBgPaddingLeft, alignContent: "center", justifyContent: "center" }}>

                    <div style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                    </div>

                    <Row style={{ width: "100%", paddingBottom: 0 }}>
                        {/* The first text lives here */}
                        <Col lg="12">
                            {/* Text View #6D53AF */}
                            <View
                                style={{
                                    height: "fit-content",
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 40,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingBottom: 25,
                                }}>
                                <div style={{
                                    width: "100%",
                                    display: "flex", flexDirection: "column",
                                    justifyContent: "center",
                                }}>

                                    <div style={{
                                        width: "97%", display: "flex",
                                        flexDirection: "column",
                                        // alignItems: "center",
                                        justifyContent: "center",
                                        gap: "16px"
                                    }}>

                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "17px",
                                            color: "#8AB4F7",
                                            color: "#FFFFFF",
                                            fontStyle: "italic"

                                        }}>Addy vs. Ellie (Try Ellie)</Text>
                                        <h1
                                            className="OverflowBreak"
                                            style={{
                                                fontSize: styles.basic.smallScreen ? "25px" : styles.basic.mainText,
                                                fontWeight: "800",
                                                color: "#FFFFFF",
                                                textAlign: "left",
                                                width: styles.basic.smallScreen ? "95%" : "80%",
                                                fontFamily: "SF-Pro-Display-Bold",
                                            }}>
                                            Your search for an alternative to Ellie (TryEllie) ends here

                                        </h1>
                                    </div>



                                    <div className="subTextDiv">


                                        <Text
                                            className="OverflowBreak"
                                            style={{
                                                fontSize: "20px",
                                                lineHeight: "26px",
                                                letterSpacing: "0.3px",
                                                width: styles.basic.smallScreen ? "95%" : "70%",
                                                color: "rgba(255, 255, 255, 1)",
                                                fontFamily: "SF-Pro-Rounded-Light",
                                            }}>
                                            Ellie (TryEllie) works only with email replies. Addy, on the other hand, can compose, reply, and follow up to your emails for you and so much more. We don’t want to minimize the hard work of the team at Ellie (TryEllie) but want to give you an objective comparison of why people prefer Addy.
                                        </Text>

                                    </div>

                                </div>
                            </View>

                            <div style={{
                                display: "flex",
                                flexDirection: styles.basic.smallScreen ? "column" : "row",
                                marginTop: "40px",
                                gap: "12px"
                                // justifyContent: "center",
                            }}>
                                <a style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#8ab4f8",
                                    padding: "20px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    borderRadius: "40px",
                                    gap: "10px",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                }} href={chromeWebstoreAddyURL} target="_blank">
                                    <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                        width="30" />
                                    <Text className="SubmitText" style={{
                                        backgroundColor: "#8ab4f8", color: "black",
                                        paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                        paddingRight: "0px"
                                    }}>Get started for free</Text>
                                </a>

                                <div className="HorizontalFlex CenterOppositeDirectionToo">
                                    <img alt="sample" width="200" height="50" src="https://i.imgur.com/xW3XP3n.png" />
                                    <div>


                                        <div>⭐⭐⭐⭐⭐ </div>
                                        <Text class="footer-helper" style={{
                                            marginLeft: "10px",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            width: "fit-content",
                                            margin: "0px"
                                        }}>Trusted by 15,000+ people</Text>
                                    </div>
                                </div>



                            </div>


                        </Col>
                        {/* Download button lives here but will be hidden on mobile */}

                    </Row>
                </View>
            </div>

            <div
                className="container"
                style={{
                    width: "100%",
                    paddingLeft: styles.basic.smallScreen ? "10px" : styles.basic.blueBgPaddingLeft,
                    justifyContent: "center",
                    paddingTop: styles.basic.smallScreen ? 60 : 100,
                    paddingBottom: styles.basic.smallScreen ? 60 : 180,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",


                }}>
                <Text style={{
                    ...styles.headerTextDark,
                    fontSize: styles.basic.titleText,
                    fontFamily: "SF-Pro-Display-Regular",
                    color: "#FFFFFF",
                    marginBottom: styles.basic.smallScreen ? "10px" : "40px",
                    width: styles.basic.smallScreen ? "90%" : "95%",

                }}>
                    Why customers choose Addy</Text>

                <Row style={{ width: "100%", paddingTop: 10 }}>
                    <Col md="6">
                        <div className="VerticalFlex" style={{ paddingTop: styles.basic.smallScreen ? 40 : 90, paddingBottom: styles.basic.smallScreen ? 10 : 60, width: "96%" }}>
                            <Text style={{
                                ...styles.headerTextNew,
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "17px",
                                color: "#8AB4F7",
                                color: "#FFFFFF",
                                fontStyle: "italic"

                            }}>Limitless</Text>
                            <Text style={{
                                ...styles.sectionSubtitle,
                                color: "#FFFFFF",
                                fontFamily: "SF-Pro-Display-Regular",
                            }}>
                                Addy can compose, reply, and follow up
                            </Text>
                            <Text
                                style={{
                                    fontSize: styles.basic.normalText,
                                    lineHeight: styles.basic.normalText * 1.7,
                                    fontWeight: "normal",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    textAlign: "left",
                                    fontFamily: "SF-Pro-Display-Light",
                                }}>
                                Addy can compose, reply, and follow up on your emails for you, but Ellie (TryEllie) only offers replies. On the (TryEllie) FAQ page, they clearly said that it can only write email replies. This means you can only use it for inbound. However, Addy can be used for both outbound and inbound, powering use cases like Sales, Customer Support, Administrative, etc.
                            </Text>




                            <br />
                            <a style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingRight: "25px",
                                paddingLeft: "25px",
                                borderRadius: "20px",
                                gap: "15px",
                                width: "fit-content",
                                color: "#000000",
                                cursor: "pointer",
                                textDecoration: "none",
                            }} href={chromeWebstoreAddyURL} target="_blank">
                                Get started for free
                            </a>

                            <br />
                        </div>
                    </Col>

                    <Col md="6">
                        <div
                            className="VerticalFlex"
                            style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: styles.basic.smallScreen ? 10 : 60,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 30,
                            }}>
                            <div className="BorderRadiusImage" style={{
                                minWidth: styles.basic.midImageWidth,
                                backgroundColor: "#D2E2FB",
                                backgroundColor: "transparent",
                            }}>
                                <VideoPlayer source={followUpDemo}
                                    width={styles.basic.midImageWidth}
                                    height={styles.basic.midImageWidth}
                                    thumbnail="https://i.imgur.com/1mZmkgn.png" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div
                className="container"
                style={{
                    width: "100%",
                    paddingLeft: styles.basic.blueBgPaddingLeft,
                    paddingBottom: styles.basic.smallScreen ? 30 : 120,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Row style={{ width: "100%" }}>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                        <div
                            className="FullParentDimensions VerticalFlex OSImageContainer"
                            style={{ alignItems: mainImageAlign }}>
                            <div className="BorderRadiusImage" style={{
                                minWidth: styles.basic.midImageWidth,
                                backgroundColor: "#D2E2FB",
                                backgroundColor: "transparent",

                            }}>
                                <VideoPlayer source={replyDemo}
                                    width={styles.basic.midImageWidth}
                                    height={styles.basic.midImageWidth}
                                    thumbnail="https://i.imgur.com/mIAuCU0.png" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                        <div className="VerticalFlex" style={styles.airportIdentifierImagediv}>
                            <Text style={{
                                ...styles.headerTextNew,
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "17px",
                                color: "#8AB4F7",
                                color: "#FFFFFF",
                                fontStyle: "italic"

                            }}>All you can email!</Text>
                            <Text style={{
                                ...styles.sectionSubtitle,
                                color: "#FFFFFF",
                                fontFamily: "SF-Pro-Display-Regular",
                            }}>Unlimited email responses</Text>
                            <Text
                                style={{
                                    fontSize: styles.basic.normalText,
                                    lineHeight: styles.basic.normalText * 1.7,
                                    fontWeight: "normal",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    textAlign: "left",
                                    fontFamily: "SF-Pro-Display-Light",
                                }}>
                                All Addy premium plan offers unlimited email responses. However, Ellie (TryEllie) only offers 25 email responses and only email replies. Their highest-tier does have unlimited replies but costs $79 per month, Addy allows you to do the same for less with the best available AI models.

                            </Text>

                            <br />

                            <a style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingRight: "25px",
                                paddingLeft: "25px",
                                borderRadius: "20px",
                                gap: "15px",
                                width: "fit-content",
                                color: "#000000",
                                cursor: "pointer",
                                textDecoration: "none",
                            }} href={chromeWebstoreAddyURL} target="_blank">
                                Get started for free
                            </a>

                            <br />
                        </div>
                    </Col>
                </Row>
            </div>

            <div
                className="container"
                style={{
                    width: "100%",
                    // paddingLeft: styles.basic.blueBgPaddingLeft,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: styles.basic.smallScreen ? 50 : 100,
                }}>
                <Row style={{ width: "100%", paddingTop: 10 }}>
                    <Col md="6">
                        <div className="VerticalFlex" style={{ paddingTop: styles.basic.smallScreen ? 20 : 40, paddingBottom: styles.basic.smallScreen ? 30 : 60, width: "96%" }}>
                            <Text style={{
                                ...styles.headerTextNew,
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "17px",
                                color: "#8AB4F7",
                                color: "#FFFFFF",
                                fontStyle: "italic"

                            }}>Better training</Text>
                            <Text style={{
                                ...styles.sectionSubtitle,
                                color: "#FFFFFF",
                                fontFamily: "SF-Pro-Display-Regular",
                            }}>Train Addy on your website or PDF files</Text>
                            <Text
                                style={{
                                    fontSize: styles.basic.normalText,
                                    lineHeight: styles.basic.normalText * 1.7,
                                    fontWeight: "normal",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    textAlign: "left",
                                    fontFamily: "SF-Pro-Display-Light",
                                }}>
                                Addy can be trained to understand your business and write in your brand voice. We offer a dedicated training dashboard where you can train on your website or PDF files, Google Drive, etc. On the other hand, Ellie (TryEllie) only offers PDFs.
                            </Text>

                            <br />

                            <a style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingRight: "25px",
                                paddingLeft: "25px",
                                borderRadius: "20px",
                                gap: "15px",
                                width: "fit-content",
                                color: "#000000",
                                cursor: "pointer",
                                textDecoration: "none",
                            }} href={chromeWebstoreAddyURL} target="_blank">
                                Get started for free
                            </a>

                            <br />

                        </div>
                    </Col>

                    <Col md="6">
                        <div
                            className="VerticalFlex"
                            style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: styles.basic.smallScreen ? 10 : 40,
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <div className="BorderRadiusImage" style={{
                                // backgroundColor: "#D2E2FB",
                                backgroundColor: "transparent",
                                minWidth: styles.basic.midImageWidth,
                            }}>
                                <VideoPlayer source={trainingDemo}
                                    thumbnail="https://i.imgur.com/SAJAcup.png"
                                    actualVideoWidth={styles.basic.demoImageWidth - 50}
                                    actualVideoHeight={styles.basic.demoImageWidth - 50}
                                    width={styles.basic.demoImageWidth - 50}
                                    height={styles.basic.demoImageWidth - 50} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>


            <div
                className="container"
                style={{
                    width: "100%",
                    paddingLeft: styles.basic.blueBgPaddingLeft,
                    paddingBottom: 120,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Row style={{ width: "100%" }}>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                        <div
                            className="FullParentDimensions VerticalFlex OSImageContainer"
                            style={{ alignItems: mainImageAlign }}>
                            <div className="BorderRadiusImage" style={{
                                minWidth: styles.basic.midImageWidth,
                                // backgroundColor: "#D2E2FB",
                                backgroundColor: "transparent",

                            }}>
                                <img width={styles.basic.midImageWidth} height={styles.basic.midImageWidth}
                                    src="https://i.imgur.com/LsQ018T.png" style={{
                                        borderRadius: "20px"
                                    }} />



                            </div>
                        </div>
                    </Col>
                    <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                        <div className="VerticalFlex" style={styles.airportIdentifierImagediv}>
                            <Text style={{
                                ...styles.headerTextNew,
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "17px",
                                color: "#8AB4F7",
                                color: "#FFFFFF",
                                fontStyle: "italic",

                            }}>Higher ratings and best reviews</Text>
                            <Text style={{
                                ...styles.sectionSubtitle,
                                color: "#FFFFFF",
                                fontFamily: "SF-Pro-Display-Regular",
                            }}>Addy has 3x more reviews and a higher rating</Text>
                            <Text
                                style={{
                                    fontSize: styles.basic.normalText,
                                    lineHeight: styles.basic.normalText * 1.7,
                                    fontWeight: "normal",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    textAlign: "left",
                                    fontFamily: "SF-Pro-Display-Light",
                                }}>

                                At the time of writing, Addy has 65 chrome reviews and a 4.5/5 stars rating. Ellie (TryEllie) on the other hand, has 18 reviews and 3.9 stars. People simply love using Addy. &nbsp;<a style={{ color: "rgba(255, 255, 255, 0.8)", }} href={chromeWebstoreAddyURL} target="_blank">See the reviews yourself</a>.

                            </Text>

                            <br />

                            <a style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingRight: "25px",
                                paddingLeft: "25px",
                                borderRadius: "20px",
                                gap: "15px",
                                width: "fit-content",
                                color: "#000000",
                                cursor: "pointer",
                                textDecoration: "none",
                            }} href={chromeWebstoreAddyURL} target="_blank">
                                Get started for free
                            </a>

                            <br />
                        </div>
                    </Col>
                </Row>
            </div>





            <div style={{
                // backgroundColor: "#404143",
                padding: "20px",
                paddingTop: "50px",
                paddingBottom: "50px",
                backgroundColor: "#34363a",
            }}>

                <div
                    className="container"
                    style={{
                        width: "100%",
                        // paddingLeft: styles.basic.blueBgPaddingLeft,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 100,
                    }}>
                    <Row style={{ width: "100%", paddingTop: 10 }}>
                        <Col md="6">
                            <div className="VerticalFlex" style={{ paddingTop: 40, paddingBottom: 60, width: "96%" }}>
                                <Text style={{
                                    ...styles.headerTextNew,
                                    fontFamily: "SF-Pro-Display-Light",
                                    fontSize: "17px",
                                    color: "#8AB4F7",
                                    color: "#FFFFFF",
                                    fontStyle: "italic",

                                }}>Fast support</Text>
                                <Text style={{
                                    ...styles.sectionSubtitle,
                                    color: "#FFFFFF",
                                    fontFamily: "SF-Pro-Display-Regular",
                                }}>Our customer service response time is within 2 hours, not 2 days.</Text>
                                <Text
                                    style={{
                                        fontSize: styles.basic.normalText,
                                        lineHeight: styles.basic.normalText * 1.7,
                                        fontWeight: "normal",
                                        color: "rgba(255, 255, 255, 0.8)",
                                        textAlign: "left",
                                        fontFamily: "SF-Pro-Display-Light",
                                    }}>
                                    We answer your support queries within 2 hours of asking. Ellie (TryEllie) can only assist you within 2 working days. We have faster support because our entire team is full-time, and dedicated to the product. You also get a direct line of access to our founder at +1 (443) 582 9119.
                                </Text>

                                <br />

                                <a style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "#8ab4f8",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingRight: "25px",
                                    paddingLeft: "25px",
                                    borderRadius: "20px",
                                    gap: "15px",
                                    width: "fit-content",
                                    color: "#000000",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                }} href={chromeWebstoreAddyURL} target="_blank">
                                    Get started for free
                                </a>

                                <br />

                            </div>
                        </Col>

                        <Col md="6">
                            <div
                                className="VerticalFlex"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    paddingTop: 40,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <div className="BorderRadiusImage" style={{
                                    backgroundColor: "#D2E2FB",
                                    minWidth: styles.basic.midImageWidth,
                                }}>
                                    <img width={styles.basic.midImageWidth} height={styles.basic.midImageWidth}
                                        src="https://i.imgur.com/xAuu39A.jpg" style={{
                                            borderRadius: "20px"
                                        }} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <h1 style={{
                    color: "#FFFFFF",
                    fontSize: "40px",
                }} className="pricing-header">Don't take our word for it</h1>
                <p style={{
                    ...styles.headerSubtitle,
                    color: "rgba(255, 255, 255, 0.8)"
                }}>15,000+ people worldwide use Addy to save hours per day on emailing.</p>
                <br />
                <div className="row" style={{ marginTop: "30px", padding: 0 }}>



                    <div className="col-lg-4 justify-content-center">

                        <div>
                            <div style={{ height: "300px" }}>
                                <iframe id="-Nci3gsVHvQUn9GHJzBq" src="https://embed-v2.testimonial.to/share/-Nci3gsVHvQUn9GHJzBq" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                            </div>
                            {/* <div style={{ height: "300px" }}>
                        <iframe id="-Nci3qKs832jDfZReW3_" src="https://embed-v2.testimonial.to/share/-Nci3qKs832jDfZReW3_" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                        </div>

                    </div>


                    <div className="col-lg-4 justify-content-center">

                        <div>
                            <div style={{ height: "350px" }}>
                                <iframe id="-Nci485hCQyeelwznqxu" src="https://embed-v2.testimonial.to/share/-Nci485hCQyeelwznqxu" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                            </div>
                            {/* <div style={{ height: "430px" }}>
                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                        </div>




                    </div>

                    <div className="col-lg-4 justify-content-center">



                        <div>
                            <div style={{ height: "300px", }}>
                                <iframe id="-N_jglbWrXdObHILrny4" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                            </div>
                            {/* <div style={{ height: "300px" }}>
                        <iframe id="-Nci61gJFakb1B4GSr7r" src="https://embed-v2.testimonial.to/share/-Nci61gJFakb1B4GSr7r" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                        </div>
                    </div>

                </div>
            </div>


            <div className="AboutContainer"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    marginTop: 40,
                    paddingBottom: 80,
                    paddingTop: 50,
                    // backgroundColor: "#202125",
                    // backgroundColor: "#F9FAFF",

                    // backgroundColor: "#34363a",
                    // backgroundColor: "#28292a"
                }}>

                <p style={{
                    ...styles.headerTextDark,
                    fontSize: styles.basic.titleText - 10,
                    fontFamily: "SF-Pro-Display-Regular",
                    color: "#FFFFFF",
                    marginBottom: "15px"
                }}>
                    Start saving hours a day on emailing</p>

                <div className="HorizontalFlex CenterContentsCompletely" style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: "100%",
                    gap: "40px"
                }}>


                    <a style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#8ab4f8",
                        padding: "15px",
                        borderRadius: "40px",
                        gap: "10px",
                        cursor: "pointer",
                        textDecoration: "none",
                    }} href={chromeWebstoreAddyURL} target="_blank">
                        <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                            width="30" />
                        <p className="SubmitText" style={{

                            backgroundColor: "#8ab4f8", color: "black",
                            paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                            paddingRight: "0px"
                        }}>Get started for free</p>
                    </a>
                </div>

            </div>


            <div className="AboutContainer container-fluid"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // justifyContent: "center",
                    paddingBottom: 80,
                    backgroundColor: "#202125",
                    backgroundColor: "rgba(255, 255, 255, 01)",
                    // backgroundColor: "#3c4043",

                    paddingTop: "70px"
                }}>

                <p style={{
                    ...styles.headerTextDark,
                    fontSize: styles.basic.titleText - 9,
                    fontFamily: "SF-Pro-Display-Regular",
                    color: "rgba(0, 0, 0, 0.8)",
                    fontWeight: "normal",
                    marginBottom: "15px"
                }}>
                    Proudly backed by</p>

                <div className="VerticalFlex CenterContentsCompletely" style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: "100%",
                    gap: "20px",
                }}>

                    <div style={{
                        display: "flex",
                        gap: 30,
                        flexWrap: "wrap"
                    }}>
                        <img src="https://i.imgur.com/v33lYtV.png"
                            width={styles.basic.smallScreen ? "300px" : "360px"} height="auto" />

                        <img src="https://i.imgur.com/AR2u6gb.png"
                            width={styles.basic.smallScreen ? "300px" : "360px"} height="auto" />
                    </div>



                    <p style={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontFamily: "SF-Pro-Display-Light",
                        fontSize: "21px",
                        width: "100%",
                        textAlign: styles.basic.smallScreen ? "left" : "center"

                    }}>
                        And notable Angels - Merline Saintil
                    </p>

                </div>
            </div>


            <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />





        </div>

    );
};

export default TryEllieAlternative;