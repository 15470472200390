// App.js - WEB
import React, { Component } from "react";
import { View, Text, Image, Modal } from "react-native-web";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from 'react-bootstrap';
import { updateDimensions, styles, } from "../styles";


class PricingFAQ extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            selectedQuestion: 0,
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        this.playerRef = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);


        this.questions = [
            {
                "title": "What is the difference between unlimited plan and enterprise plan?",
                "description": "In the Enterprise plan, you can invite your team members to have access to a trained AI model. In the Enterprise plan, the AI can also write emails in the background and save them to your drafts, so your team can review before sending."
            },
            {
                "title": "Can I train Addy on my website?",
                "description": "Yes! With all of our paid plans, you can easily train Addy on your website or PDF files to understand your enterprise and write personalized emails. <a href='/how-to-train' target='_blank'>Click here to learn how to train Addy.</a>"
            },
            {
                "title": "Can I cancel my subscription at any time?",
                "description": "Yes, you can cancel your account at any time. Our help center has information on how to <a href='/help-center' target='_blank'>manage your subscription.</a>"
            },
            {
                "title": "Is there a limit on the number of emails Addy can write per day?",
                "description": "There's no limit on the paid plans but there is a limit on the free plan."
            },
            
            {
                "title": "Can Addy learn my writing style and write in my brand voice?",
                "description": "Yes! Addy can learn your writing style to generate emails that sound like you, and your brand voice."
            },
            
        ]

    }


    handleQuestionClick = index => {
        this.setSelectedQuestion(index);
    };

    setSelectedQuestion = (index) => {
        this.setState({ selectedQuestion: index });
    };

    handleVideo(state) {
        if (state.inView) {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.playVideo();
        } else {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.pauseVideo();
        }
    }


    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };

    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer" style={{
                    backgroundColor: "rgb(32, 33, 36)"
                }}>
                    {/* <Header /> */}


                    <div className="PrimarySectionContainer" style={{
                        backgroundColor: "rgb(32, 33, 36)",
                        paddingBottom: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}>

                        {/* First background */}
                        <View style={{ alignContent: "center", justifyContent: "center" }}>

                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <p style={{
                                    ...styles.headerTextDark,
                                    fontSize: styles.basic.titleText,
                                    fontFamily: "SF-Pro-Display-Regular",
                                    color: "#FFFFFF",
                                    marginBottom: "15px"
                                }}>
                                    Frequently asked questions</p>
                            </div>
                            <Row>
                                <Col md={10} style={{ marginBottom: "30px", padding: "0px", width: "100%" }}>
                                    {this.questions.map((question, index) => (
                                        <div
                                            key={index}
                                            className={`p-4 question ${index === this.state.selectedQuestion ? "active" : ""}`}
                                            onClick={() => this.handleQuestionClick(index)}>
                                            <div className="HorizontalFlex CenterOppositeDirectionToo space-between">
                                                <p className="question-title" style={{
                                                    fontSize: "18px",
                                                }}>{question.title}</p>
                                                <Button variant="link" style={{
                                                    textDecoration: "none",
                                                    fontSize: "25px",
                                                    // color: "#1D344B",
                                                    color: "#FFFFFF",
                                                    backgroundColor: "#745dde",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                }}>{index === this.state.selectedQuestion ? "-" : "+"}</Button>
                                            </div>

                                            {index === this.state.selectedQuestion && <p className="question-description" style={{
                                                fontSize: "17px"
                                            }} dangerouslySetInnerHTML={{ __html: question.description}}></p>}


                                            {index === this.state.selectedQuestion && <div className="answer" style={{
                                                display: styles.basic.smallScreen ? "flex" : "none",
                                            }}>
                                                <img src={question.img}
                                                    style={{
                                                        width: styles.basic.demoImageWidth,
                                                    }}
                                                    className="DemoImage" />
                                            </div>}


                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </View>
                    </div>

                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <PricingFAQ />;
}