import React, { useState, useRef, useEffect } from 'react';
import './navigation.css';

const Navigation = () => {
    const navDiv = useRef();

    const [isOpen, setIsOpen] = useState(false);
    
    const [isOpenSupport, setIsOpenSupport] = useState(false);
    const [isOpenProductNav, setIsOpenProductNav] = useState(false);

    useEffect(() => {
        // Inside this function, we can use navDiv.current to access the DOM node.
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then the menu should be closed.
          if (!navDiv.current.contains(e.target)) {
            toggleAllOtherNavsOff();
          }
        }
    
        // Attach the listeners to the document
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        // Cleanup
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, []);  // Empty array ensures that effect is only run on mount and unmount

    const toggleSupportNav = () => {
        toggleAllOtherNavsOff();
        setIsOpenSupport(!isOpenSupport);
    }
    const toggleProducttNav = () => {
        toggleAllOtherNavsOff();
        setIsOpenProductNav(!isOpenProductNav);
    }

    const toggleAllOtherNavsOff = () => {
        setIsOpenProductNav(false);
        setIsOpenSupport(false);
    }

    const toggleNav = () => setIsOpen(!isOpen);

    return (
        <nav ref={navDiv} className="navigation">
            <button onClick={toggleNav} className="nav-toggle">
                ☰
            </button>
            <div style={{ textAlign: "left" }} className={`nav-links ${isOpen ? 'active' : ''}`}>
                <a className='nav-link' href="/about">About Us</a>
                <a className='nav-link' href="/pricing?ref=pricing-main-nav">Pricing</a>
                <a className='nav-link' target="_blank" href="https://addy.beehiiv.com">Blog</a>

                <div className='nav-item'> {/* New Dropdown Menu */}
                    <a className='nav-link' onClick={toggleProducttNav}>Product</a>
                    {isOpenProductNav ? <div className="VerticalFlex navigation-dropdown-div">
                        <a className='nav-link' href="https://addyai.notion.site/Addy-for-Sales-3ff5c5a4c9b54958b958d6c415cdb444" target="_blank">Sales</a>
                        <a className='nav-link' href="https://addyai.notion.site/Addy-for-Customer-Support-b7aebd385c9f4233aa111bedb1548fd3" target="_blank">Customer Support</a>
                    </div> : <></>}
                    
                </div>

                <div className='nav-item'> {/* New Dropdown Menu */}
                    <a className='nav-link' onClick={toggleSupportNav}>Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                    {isOpenSupport ? <div className="VerticalFlex navigation-dropdown-div">
                        <a className='nav-link' href="/contactus">Contact Us</a>
                        <a className='nav-link' href="/help-center" target="_blank">Help Center</a>
                    </div> : <></>}
                    
                </div>

            </div>
        </nav>
    );
};

export default Navigation;
