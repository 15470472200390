class Global{
    constructor(){
        
    }
    static emailjsId = "";
    static recaptchaSiteKey = "";
    static recaptchaSecretKey = "";

    static getEmailJsId(){
        return this.emailjsId;
    }
    static getRecaptchaSiteKey(){
        return this.recaptchaSiteKey;
    }
    static getRecaptchaSecretKey(){
        return this.recaptchaSecretKey;
    }
    
}
export default Global;