// App.js - WEB
import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TouchableOpacity } from "react-native";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateDimensions, styles } from "../styles";
import Footer from "../footer";
import HeaderDark from "../headerDark";
import JSONPretty from 'react-json-pretty';

const API_URL = "https://hey-addy.web.app";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");
    const userID = urlParams.get("uid");
    const code = urlParams.get("code");

    this.state = {
      email: email,
      userID: userID,
      code: code,
      userData: {},
      notFound: "",
    };
  }

  updateDimensions() {
    updateDimensions();
    this.setState();
  }

  componentDidMount() {
    this.updateDimensions();
    this.fetchUserData();

    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillMount() {}

  fetchUserData = () => {
    fetch(`${API_URL}/user/del-account-retrieve`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // add the data to the request body
        uid: this.state.userID,
        code: this.state.code,
        email: this.state.email,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // Add the success responses
          this.setState({
            userData: response.data,
          });
          if (response.reason && response.reason == "UserNotFound") {
            this.setState({
              notFound: "This user was not found or may have been deleted",
            });
          }
          return;
        }
        this.setState({
          userData: {
            user: "Failed to get user data.",
          },
        });
      })
      .catch((error) => {
        this.setState({
          userData: {
            user: "Failed to get user data.",
          },
        });
      });
  };

  deleteAccount = () => {
    const loaderView = document.getElementById("loading-overlay");
    if (loaderView) loaderView.style.display = "block";

    const sureToDelete = window.confirm("Are you sure? \nThis cannot be undone");
    if (!sureToDelete) {
      if (loaderView) loaderView.style.display = "none";
      return;
    }

    fetch(`${API_URL}/user/del-user-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // add the data to the request body
        uid: this.state.userID,
        code: this.state.code,
        email: this.state.email,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // Add the success responses
          this.setState({
            userData: {
              user: "This user does not exist",
            },
            successMessage: "Successfully deleted user!",
          });
          if (loaderView) loaderView.style.display = "none";
          return;
        }
        if (response.reason && response.reason == "FailedToDeleteUser") {
          this.setState({
            notFound: "Failed to delete this user",
          });
          if (loaderView) loaderView.style.display = "none";
        }
        if (loaderView) loaderView.style.display = "none";
        return;
      })
      .catch((error) => {
        this.setState({
          userData: {
            user: "Failed to delete user.",
          },
        });
        if (loaderView) loaderView.style.display = "none";
      });
  };

  MainPage() {
    return (
      <div className="AboutUsContainer" style={{ height: "100vh" }}>
        <HeaderDark />

        {/* How */}
        <div
          className="AboutContainer"
          style={{
            width: "100%",
            paddingLeft: styles.basic.blueBgPaddingLeft,
            justifyContent: "center",
            marginBottom: 50,
            paddingTop: 80,
          }}>
          <Row style={{ width: "100%", paddingTop: 30 }}>
            <Col md="12">
              <br />
              <br />
              <div className="HorizontalFlex CenterOppositeDirectionToo CenterContents">
                <h2 style={{ color: "#2B1C50" }}>
                  Delete Your Account For:{" "}
                  <mark
                    style={{
                      backgroundColor: "transparent",
                      color: "#2B1C50",
                      fontStyle: "italic",
                    }}>
                    {this.state.email}
                  </mark>
                </h2>
              </div>

              <br />
              <br />

              <div>
                <Row>
                  <Col md="6">
                    <h4 style={{ color: "#2B1C50" }}>The data we have for you</h4>
                    <div
                      className="FullParentDimensions CenterContents"
                      style={{
                        backgroundColor: "#E9E9E9",
                        borderRadius: "10px",
                        padding: "15px",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                      }}>
                      <div style={{
                        maxHeight: "500px",
                        overflowY: "scroll",
                      }}>
                        {/* <p>{JSON.stringify(this.state.userData)}</p> */}
                        <JSONPretty id="json-pretty" data={this.state.userData}></JSONPretty>

                      </div>
                      <p>{this.state.notFound}</p>
                    </div>
                  </Col>

                  <Col md="6">
                    <div
                      className="VerticalFlex"
                      style={{
                        width: "100%",
                      }}>
                      <div>
                        <h4 style={{ color: "#2B1C50", marginBottom: "7px" }}>Actions</h4>
                      </div>

                      <TouchableOpacity className="SubmitButtonInstall" onPress={() => this.deleteAccount()}>
                        <p className="SubmitTextInstall2">Delete account and data</p>
                      </TouchableOpacity>

                      {/* <div>
                                        <p style={{color: "#2B1C50", marginBottom: "15px"}}>
                                            Enter their emails below separated by comma
                                        </p>
                                    </div> */}

                      <p
                        id="successFullInvite"
                        style={{
                          color: "green",
                        }}>
                        {this.state.successMessage}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

              <br />
              <br />

              <br />
              <br />
              {/* <div style={{width: "100%",
                        border: "0.5px solid grey",
                        // borderTop: "1px solid #E9E9E9",
                    }}></div> */}

              <br />
              <br />
            </Col>
          </Row>
        </div>

        <div id="loading-overlay">
          <div className="loader"></div>
        </div>

        {/* <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" /> */}
      </div>
    );
  }

  render() {
    return <div style={{ height: "100vh" }}>{this.MainPage()}</div>;
  }
}
export default DeleteAccount;
