// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions, Image } from "react-native";

import { updateDimensions, styles } from "./styles";
import "./App.css";
import Navigation from "./components/navigation/navigation";
// import "bootstrap/dist/css/bootstrap.min.css";

var headerHorizontalPadding = 70;
var headerShadowRadius = 5;
var headerShadowOpacity = 0.7;

class HeaderDark extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    updateDimensions() {
        updateDimensions();
    }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));

        document.body.addEventListener("scroll", () => {
            const headerElement = document.getElementById("addy-main-header");
            const scrollPosition = document.body.scrollTop;

            if (scrollPosition > 500) {
                if (headerElement) {
                    headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
                    headerElement.style.backgroundColor = "rgb(32, 33, 36)";
                }

            } else {
                if (headerElement) {
                    headerElement.style.boxShadow = "none";
                    headerElement.style.backgroundColor = "rgb(32, 33, 36)";
                }

            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() { }

    onAppLogoClick = () => {
        window.location.href = "/";
    };

    gotToLocalHref = (href) => {
        window.location.href = href;
    };
    MainPage() {
        return (
            <div
                id="addy-main-header"
                className="HeaderContainer"
                style={{
                    height: 81,
                    width: "100%",
                    paddingLeft: styles.basic.headerHorizontalPadding,
                    top: 0,
                    shadowColor: "#000000",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingRight: styles.basic.headerHorizontalPadding,
                    alignItems: "center",
                    backgroundColor: "rgb(32, 33, 36)"

                }}>
                <div className="AppLogo" onClick={this.onAppLogoClick}>
                    <Image
                        style={{ width: 22, height: 22, marginRight: 12 }}
                        source={require("./img/logo.png")}
                        resizeMode="contain"
                    />
                    <p className="AppTitleText" style={{
                        color: "white",
                    }}>Addy</p>
                    {/* <mark style={{
                                backgroundColor: "transparent",
                                color: "rgba(255, 255, 255, 0.7)",
                                fontFamily: "SF-Pro-Display-Light"
                            }}>&nbsp;| Email</mark> */}


                </div>

                <div className="HeaderOptionsDiv" style={{
                    display: "flex", flexDirection: "column",
                    position: "absolute",
                    top: 15,
                    right: 6,
                    // paddingRight: 20,  
                    alignItems: "flex-end"
                }}>

                    <Navigation/>

                    {/* <p onClick={() => this.gotToLocalHref("/pricing")} className="HeaderOptionButton">Pricing</p> */}
                </div>
            </div>
        );
    }

    render() {
        return <div>{this.MainPage()}</div>;
    }
}

export default HeaderDark;