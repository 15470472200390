// App.js - WEB
import React, { Component } from "react";

import { updateDimensions, styles } from "../../styles";

class PricingComparison extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
    }


    MainPage() {
        return (

            <div class="container mx-auto px-4 py-12 bg-grey">
                <div class="overflow-x-auto bg-white rounded-3xl">
                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 bg-transparent">
                            <tr>
                                <th scope="col" class="py-3 px-6"></th>
                                <th scope="col" class="py-3 px-6 text-2xl">
                                    Free
                                    <div
                                        style={{minWidth: "110px"}}
                                        className="subscribe-button"
                                        onClick={() => {this.props.getStartedClick("free")}}>
                                        Get started
                                    </div>   
                                </th>
                                <th scope="col" class="py-3 px-6 text-2xl">
                                    Unlimited
                                    <div
                                        id="unlimited-comparison-button"
                                        className="subscribe-button"
                                        onClick={() => {this.props.getStartedClick("unlimited")}}>
                                        Get started
                                    </div>   
                                </th>
                                <th scope="col" class="py-3 px-6 text-2xl">
                                    Enterprise
                                    <div
                                        className="subscribe-button"
                                        onClick={() => {this.props.getStartedClick("enterprise")}}>
                                        Contact Us
                                    </div>   
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <!-- Usage Section --> */}
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Email generations per day</th>
                                <td class="py-4 px-6">3</td>
                                <td class="py-4 px-6">Unlimited</td>
                                <td class="py-4 px-6">Unlimited</td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Personalization</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Train on your website</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Train with your files</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">File upload volume</th>
                                <td class="py-4 px-6">0 MB</td>
                                <td class="py-4 px-6">100 MB</td>
                                <td class="py-4 px-6">Unlimited</td>
                            </tr>

                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Background email response</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            
                            {/* <!-- Features Section --> */}
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Auto summarize long emails</th>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Auto suggested replies</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Change tone, length, language </th>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>
                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">All languages</th>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>

                            <tr class="border border-gray-700">
                                <th scope="row" class="py-4 px-6 font-medium text-grey-900 whitespace-nowrap">Shared team knowledge base</th>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-times text-grey-500"></i></td>
                                <td class="py-4 px-6"><i class="fas fa-check text-green-500"></i></td>
                            </tr>

    
                           
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }

    render() {
        return <div>{this.MainPage()}</div>;
    }
}

export default PricingComparison;




/**
 
 
 */