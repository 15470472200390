import React, { useState, useRef } from 'react';
import accenture from "../img/logos/accenture.svg";
import google from "../img/logos/google.svg";
import amazon from "../img/logos/amazon.svg";
import tiktok from "../img/logos/tiktok.svg";
import roblox from "../img/logos/roblox.svg";
import yahoo from "../img/logos/yahoo.svg";
import rakuten from "../img/logos/rakuten.svg";
import {styles} from "../styles";
import okra from "../img/logos/okra.svg";
import './customers.css';

const Customers = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showThumbnail, setShowThumbnail] = useState(true);
    const videoRef = useRef(null);


    const customers = props.customerService ? [
        
    ] : [
        accenture,
        google,
        roblox,
        rakuten,
        tiktok,
        yahoo,
        amazon,
    ]

    const customerRender = [];

    const imageWidth = styles.basic.smallScreen ? 90 : 140;

    return (
        <div className="customer-container" style={{
            display: styles.basic.smallScreen ? "contents" : "flex !important",
        }}>
            <div className="customer" style={{
                animation: styles.basic.smallScreen ? "none" : "scroll 71.68s linear 0s infinite",
                flexWrap: styles.basic.smallScreen ? "wrap" : "none",
            }}>
                <div className="initial-child-container" style={{
                    flex: styles.basic.smallScreen ? "content" : "0 0 auto"
                }}>

                    {customers.map((logo) => (
                        <div className="customer-child" style={{
                            marginLeft: styles.basic.smallScreen ? "20px" : "45px",
                            marginRight: styles.basic.smallScreen ? "20px" : "45px",
                        }}>
                            <img alt="customer" loading="lazy"
                                width={`${imageWidth}`} height={`${imageWidth / 2}`}
                                decoding="async"
                                data-nimg="1"
                                class="mx-6 h-10 w-20 object-contain sm:mx-12 sm:h-20 sm:w-40"
                                src={logo} style={{
                                    color: "transparent"
                                }} />
                        </div>
                    ))}

                </div>
            </div>

            {!styles.basic.smallScreen && <div className="customer" style={{
                animation: styles.basic.smallScreen ? "none" : "scroll 71.68s linear 0s infinite",
                flexWrap: styles.basic.smallScreen ? "wrap" : "none",
            }}>
                <div className="initial-child-container" style={{
                    flex: styles.basic.smallScreen ? "content" : "0 0 auto"
                }}>

                    {customers.map((logo) => (
                        <div className="customer-child" style={{
                            marginLeft: styles.basic.smallScreen ? "20px" : "45px",
                            marginRight: styles.basic.smallScreen ? "20px" : "45px",
                        }}>
                            <img alt="customer" loading="lazy"
                                width={`${imageWidth}`} height={`${imageWidth / 2}`}
                                decoding="async"
                                data-nimg="1"
                                class="mx-6 h-10 w-20 object-contain sm:mx-12 sm:h-20 sm:w-40"
                                src={logo} style={{
                                    color: "transparent"
                                }} />
                        </div>
                    ))}

                </div>
            </div>}

            {!styles.basic.smallScreen && <div className="customer" style={{
                animation: styles.basic.smallScreen ? "none" : "scroll 71.68s linear 0s infinite",
                flexWrap: styles.basic.smallScreen ? "wrap" : "none",
            }}>
                <div className="initial-child-container" style={{
                    flex: styles.basic.smallScreen ? "content" : "0 0 auto"
                }}>

                    {customers.map((logo) => (
                        <div className="customer-child" style={{
                            marginLeft: styles.basic.smallScreen ? "20px" : "45px",
                            marginRight: styles.basic.smallScreen ? "20px" : "45px",
                        }}>
                            <img alt="customer" loading="lazy"
                                width={`${imageWidth}`} height={`${imageWidth / 2}`}
                                decoding="async"
                                data-nimg="1"
                                class="mx-6 h-10 w-20 object-contain sm:mx-12 sm:h-20 sm:w-40"
                                src={logo} style={{
                                    color: "transparent"
                                }} />
                        </div>
                    ))}

                </div>
            </div>}

        </div>
    );
};

export default Customers;
