// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions, Image, TouchableOpacity, View } from "react-native";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from ".././header";
import Footer from "../footer";
import HeaderDark from "../headerDark";
var blueBgPaddingLeft = 70;


let API_URL = "https://hey-addy.web.app";

class PaymentSuccessful extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const uid = urlParams.get("uid") == null ? undefined : urlParams.get("uid");
        let text = "Your reciept will be sent to your email. Please close this tab and Continue back to Addy AI";
        let header = "Thank you for your payment";

        const clientId = urlParams.get("clientId") == null ? undefined : urlParams.get("clientId");

        if (clientId) {
            // Send a message to the server to send a web socket message, to 
            // that specific client that request was successful or failed.
            const fetchURL = `https://addy-web-socket-zquodzeuva-uc.a.run.app/send/${clientId}`;
            // Define the post request
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: "successful" })
            };

            // Make the request
            fetch(fetchURL, requestOptions)
                .then(response => response)
                .then(data => console.log(data))
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        this.state = {
            successMessage: "",
            uid: uid,
            header: header,
            text: text,
            name: "",
            email: "",
        };


        if (uid) this.fetchUserData(uid);

    }

    updateDimensions() { }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    async fetchUserData(uid, email = "null") {
        await fetch(`${API_URL}/user/profiles?uid=${uid}&email=${email}`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    this.setState({
                        name: ", " + data.user.name,
                        email: "(" + data.user.email + ")",
                    })
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    onAppLogoClick = () => {
        window.location.href = "/";
    };

    onexplorePremiumClick = () => {
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
        // const uid = this.state.uid;
        // if (uid && uid != undefined && uid != null) {
        //     window.open(`https://addy-ai.com/pricing?uid=${uid}`, '_blank');
        // } else {
        //     // No UID redirect to stripe with email
        //     window.open("https://addy-ai.com/pricing", '_blank');
        // }
    };

    MainPage() {
        return (
            <div className="AboutUsContainer" style={{ height: "100vh" }}>
                <HeaderDark />

                {/* How */}
                <div
                    className="AboutContainer"
                    style={{
                        width: "100%",
                        paddingLeft: blueBgPaddingLeft,
                        justifyContent: "center",
                        marginBottom: 50,
                        paddingTop: 80,
                    }}>
                    <Row style={{ width: "100%", paddingTop: 30 }}>
                        <Col md="12">
                            <br />
                            <br />
                            <div className="VerticalFlex CenterOppositeDirectionToo CenterContents">
                                <h2 className="pricing-header">Thank you for your payment{this.state.name}</h2>
                                <h2 className="pricing-header">You have succesfully upgraded to premium! &nbsp; &#127881;</h2>
                            </div>
                            <p className="pricing-sub-helper" style={{ fontSize: "20px", marginTop: "20px" }}>
                                Your reciept will be sent to your email. Please close this tab and Continue back to Addy AI &#9889;
                            </p>

                            <br />
                            <br />

                            <div>
                                <Row>
                                    <Col md="12" style={{
                                        display: "flex", flexDirection: "column", alignItems: "center"
                                    }}>
                                        <div className="FullParentDimensions CenterContents cute-dog-background">
                                            <img src="https://i.imgur.com/dftapPB.gif" height="400" width="500" />
                                        </div>
                                    </Col>

                                    <Col md="12" style={{
                                        display: "flex", flexDirection: "column", alignItems: "center"
                                    }}>
                                        <div
                                            className="VerticalFlex"
                                            style={{
                                                width: "100%",
                                                display: "flex", flexDirection: "column", alignItems: "center"
                                            }}>
                                            <br />
                                            {/* <h5>Please close this tab and Continue back to Addy AI</h5> */}

                                            <p></p>
                                            {/* <div>
                        <div onClick={() => this.onexplorePremiumClick()} className="premium-button">
                          Update Chrome Extension
                        </div>
                      </div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <br />
                            <br />

                            <br />
                            <br />

                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>

                <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />
            </div>
        );
    }

    render() {
        return <div style={{ height: "100vh" }}>{this.MainPage()}</div>;
    }
}

export default PaymentSuccessful;
