// App.js - WEB
import React, { Component } from "react";
import { View, Text, Image, Modal } from "react-native-web";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from 'react-bootstrap';
import { updateDimensions, styles, } from "../../styles.js";


class Faq extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            selectedQuestion: 0,
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        this.playerRef = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);


        this.questions = [
            {
                "title": "Can Addy be trained to understand my business and writing style?",
                "description": "Yes! You can easily train Addy on your website or PDF files to understand your business and write personalized emails. Addy can also learn your writing style to generate emails that sound like you. <a href='/how-to-train' target='_blank'>Click here to learn how to train Addy.</a>"
            },
            {
                "title": "Does it work on Outlook?",
                "description": "Yes. Our Chrome Extension works on both Gmail and Outlook on the Chrome Browser."
            },
            {
                "title": "Does Addy read my emails?",
                "description": "No, Addy does not read your emails. You can choose to provide Addy with some of your sample sent emails to learn how you write, so it can write emails that sound like you. This can be turned on/off anytime in the settings."
            },
            {
                "title": "Is there a limit on the number of emails Addy can write",
                "description": "To sustain our operations, there is a daily limit on the free plan. If you want unlimited emails please upgrade to one of our premium plans."
            },
            
            {
                "title": "How secure is Addy AI?",
                "description": "We believe privacy and security should not be a privilege but a fundamental right! Addy is private & secure by default. We do not store your data! If you choose to have Addy learn your writing style, the data is only used to generate a response and is not accessible to any other party. We promise to never sell, share, or do anything malicious with your data. Our sole purpose is to enhance your productivity and we make money by helping you be more productive!"
            },
            {
                "title": "Who are the founders and why should I trust you?",
                // "description": "Hello! We are Michael and Charles, two very experienced software engineers. Just like you, we wanted to optimize our email experience, so we built Addy AI. It started as a fun project for ourselves but quickly became really popular because of how useful it is, and we even got a shout out from Elon Musk. Since then, Addy has grown to be a venture-backed company funded by Carnegie Mellon University, and other notable angel investors in Silicon Valley. We have over 15,000 customers globally who love using Addy AI. Our product is also being used in the world’s top and most secure companies like Google, Salesforce & Intuit. We have an average rating of 4.5/5 stars. We're proud of what we’ve been able to achieve as a team and excited for what’s to come."

                "description": "Hello! We are <a href='/about' target='_blank'>Michael and Charles</a>, two very experienced software engineers. Just like you, we wanted to optimize our email productivity, so we built Addy AI. It started as a fun project but quickly became popular because of its usefulness, and we even got a shout-out from Elon Musk. Addy is now a venture-backed company funded by Carnegie Mellon University and notable angel investors in Silicon Valley. We have over 15,000 customers globally who love using the product. It's also being used in the world’s top and most secure companies like Google, Salesforce & Intuit, and we have an average rating of 4.5/5 stars. We're proud of what we’ve been able to achieve as a team and excited for what’s to come."

            }
            
        ]

    }


    handleQuestionClick = index => {
        this.setSelectedQuestion(index);
    };

    setSelectedQuestion = (index) => {
        this.setState({ selectedQuestion: index });
    };

    handleVideo(state) {
        if (state.inView) {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.playVideo();
        } else {
            if (this.playerRef && this.playerRef.current) this.playerRef.current.internalPlayer.pauseVideo();
        }
    }


    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };

    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer" style={{
                    backgroundColor: "rgb(32, 33, 36)"
                }}>
                    {/* <Header /> */}


                    <div className="PrimarySectionContainer" style={{
                        backgroundColor: "rgb(32, 33, 36)",
                        paddingBottom: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}>

                        {/* First background */}
                        <View style={{ alignContent: "center", justifyContent: "center" }}>

                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <p style={{
                                    ...styles.headerTextDark,
                                    fontSize: styles.basic.titleText,
                                    fontFamily: "SF-Pro-Display-Regular",
                                    color: "#FFFFFF",
                                    marginBottom: "15px"
                                }}>
                                    Frequently Asked Questions</p>
                            </div>
                            <Row>
                                <Col md={10} style={{ marginBottom: "30px", padding: "0px", width: "100%" }}>
                                    {this.questions.map((question, index) => (
                                        <div
                                            key={index}
                                            className={`p-4 question ${index === this.state.selectedQuestion ? "active" : ""}`}
                                            onClick={() => this.handleQuestionClick(index)}>
                                            <div className="HorizontalFlex CenterOppositeDirectionToo space-between">
                                                <p className="question-title" style={{
                                                    fontSize: "18px",
                                                }}>{question.title}</p>
                                                <Button variant="link" style={{
                                                    textDecoration: "none",
                                                    fontSize: "25px",
                                                    // color: "#1D344B",
                                                    color: "#FFFFFF",
                                                    backgroundColor: "#745dde",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                }}>{index === this.state.selectedQuestion ? "-" : "+"}</Button>
                                            </div>

                                            {index === this.state.selectedQuestion && <p className="question-description" style={{
                                                fontSize: "17px"
                                            }} dangerouslySetInnerHTML={{ __html: question.description}}></p>}


                                            {index === this.state.selectedQuestion && <div className="answer" style={{
                                                display: styles.basic.smallScreen ? "flex" : "none",
                                            }}>
                                                <img src={question.img}
                                                    style={{
                                                        width: styles.basic.demoImageWidth,
                                                    }}
                                                    className="DemoImage" />
                                            </div>}


                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </View>
                    </div>

                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <Faq />;
}