import React, { useState, useRef } from 'react';
import './video.css';
import playButton from "../img/play_button.png"

const VideoPlayer = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showThumbnail, setShowThumbnail] = useState(true);
    const videoRef = useRef(null);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
            setShowThumbnail(false);
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="video-container" style={{
            width: props.width,
            height: props.height,
        }}>
            {showThumbnail && <img src={props.thumbnail} alt="Video Thumbnail" className="video-thumbnail" />}
            <video style={{
                width: props.actualVideoWidth ? props.actualVideoWidth: "100%",
                height: props.actualVideoHeight ? props.actualVideoHeight: "100%",
                borderRadius: "20px"
            }} height={props.height} ref={videoRef} className="video-player" src={props.source} onClick={togglePlayPause}></video>
            <div className="play-button-container" onClick={togglePlayPause}>
                {isPlaying ? null : (
                    <span className="play-button">
                        <img src={playButton} width="30" height="30" />
                    </span>
                )}
            </div>
        </div>
    );
};

export default VideoPlayer;
