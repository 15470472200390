// App.js - WEB
import React, { Component } from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity, Text, ImageBackground, Image, Modal } from "react-native-web";
import { FaTwitter, FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Global from "./Global";
import "./App.css";
import { updateDimensions, styles } from "./styles";
import { unregister } from "./registerServiceWorker";
import Header from "./header";
import Youtube from "react-youtube";
import Footer from "./footer";
import CustomerReel from "./CustomerReel";
import { InlineWidget } from "react-calendly";

import { ref } from "firebase/database";
import { database } from "./firebase.js";
import Demo from "./demos";
import FeatureCard from "./card";

import multiChannelImage from "./img/multichannel_2.png";
import trainingImage from "./img/training_2.png";
import repliesForYouImage from "./img/replies_for_you.gif";
import demoImage from "./img/email_main_demo.gif";
import VideoPlayer from "./components/video";
import zeroInbox from "./img/zero_inbox.gif";
import Customers from "./components/customers";
import MultiLingualDemo from "./components/multilingualDemo";
import FooterStealth from "./footerStealth.js";
import HeaderDark from "./headerDark.js";

var composeDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Fcompose_demo_simple.mp4?alt=media&token=ea96c05b-9a8e-4bd2-9397-4536f93b431c";
var followUpDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ffollow_up_demo_simple.mp4?alt=media&token=cb5ca2e6-2558-4a8f-8b8f-63a80a6e2d2e";
var trainingDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ftraining_demo_simple.mp4?alt=media&token=2d5eea3c-4e5b-4577-bb68-d19b6a93f329";
var replyDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Freply_demo_simple.mp4?alt=media&token=be11ab20-39ae-497e-9d32-f65fc450b71b";



var numEmailSent = 0;

var mainImageAlign = "center";
var midImageWidth = 400;
var mainText = 50;
var subText = 24;
var blueBgPaddingLeft = 60;
var headerHorizontalPadding = 60;
var confirmNameText = 48;

let email = "";
const visitId = Math.round(Math.random() * 1000);
console.log("visitId", visitId);

class AboutUsStealth extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);

        // Log this visit
        this.logVisit(formattedVisitSource);
    }
    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();
        unregister();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();

        document.body.addEventListener("scroll", () => {
            const headerElement = document.getElementById("addy-main-header");
            const scrollPosition = document.body.scrollTop;

            if (scrollPosition > 500) {
                if (headerElement) {
                    headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            } else {
                if (headerElement) {
                    headerElement.style.boxShadow = "none";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            }
        });
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    logVisit(source) {
        return;
    }

    logClickChrome(source) {
        return;
    }

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };
    sendConfirmation = (email, endpoint) => {
        // hit endpoint with email
        // If it failed try once again.
        numEmailSent += 1;
        const url = new URL("https://api.ratemynft.app/api/sendlandingconfirmationemail");
        const data = {
            email: email,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify(response));
                if (!response.metadata || response.metadata.result !== "success") {
                    // Something went wrong TODO: Log this.
                    if (numEmailSent < 3) {
                        // this.sendConfirmation(email);
                    }
                } else {
                    // Success response.
                }
            })
            .catch((error) => {
                // TODO: Log this error
                if (numEmailSent < 3) {
                    this.sendConfirmation(email);
                }
            });
    };
    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };
    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer" style={{
                    backgroundColor: "rgba(255, 255, 255, 1)"
                }}>
                    <HeaderDark/>


                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                // border: "1px solid yellow"
                                backgroundColor: "#202124",
                            }
                        }>
                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingTop: 150,
                                paddingBottom: 50,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",


                            }}>
                            <p style={{
                                ...styles.headerTextDark,
                                fontSize: styles.basic.titleText,
                                fontFamily: "SF-Pro-Display-Regular",
                                color: "rgba(255, 255, 255, 1)",
                                marginBottom: "40px"
                            }}>
                                Our Team</p>

                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                                    <View style={{ paddingTop: 30, paddingBottom: 60, width: "96%" }}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#c7c7c7"

                                        }}>CEO</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "rgba(255, 255, 255, 0.9)",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>
                                            Michael Vandi
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Michael was born in Sierra Leone and moved to the U.S. in 2018. He completed his undergraduate degree in just 2 years at the University of Baltimore, graduating top of his class and later earned a Master's in Software Engineering from Carnegie Mellon University. He interned at the National Science Foundation (NSF) where he discovered a passion for AI and met his future co-founder, Carlos.
                                        </Text>

                                        <br />

                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Afterward, he led a team of 10 engineers at NASA S.U.I.T.S. developing spacesuit informatics displays, published four peer-reviewed papers, and joined Amazon Web Services as a Software Engineer building high-throughput distributed systems.
                                        </Text>

                                        <br />

                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            In his spare time, Michael teaches coding on YouTube, collects colorful socks, enjoys mountain biking, and tends to his vegetable garden.
                                        </Text>

                                        <br />

                                        {/* <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Email: michael@addy-ai.com
                                        </TouchableOpacity> */}

                                        <br />
                                    </View>
                                </Col>

                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: 30,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                        }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            backgroundColor: "transparent",
                                        }}>
                                            <img src="https://i.imgur.com/I6rJ4Q9.jpg"
                                                style={{ borderRadius: "20px" }}
                                                width={styles.basic.midImageWidth}
                                                height={styles.basic.midImageWidth} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                paddingBottom: 120,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Row style={{ width: "100%" }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
                                    <div
                                        className="FullParentDimensions VerticalFlex OSImageContainer"
                                        style={{ alignItems: mainImageAlign }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            backgroundColor: "transparent",

                                        }}>
                                            <img src="https://i.imgur.com/wJolzFo.jpg"
                                                style={{ borderRadius: "20px" }}
                                                width={styles.basic.midImageWidth}
                                                height={styles.basic.midImageWidth} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                                    <View style={styles.airportIdentifierImageView}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#c7c7c7",

                                        }}>CTO</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "rgba(255, 255, 255, 0.9)",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Charles (Carlos) Karpati</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Carlos has been coding since age 14 and has over a decade of software development experience. He holds a Master's in Computer Information Systems from the University of Maryland, Baltimore County.
                                        </Text>
                                        <br />

                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>

                                            Post-graduation, he worked at a National Science Foundation (NSF)-funded research institute in Baltimore where he contributed to over 95% of the project’s code. He also served as the technical lead for an NSF AI initiative at the University of Baltimore, where he met his co-founder, Michael.
                                        </Text>
                                        <br />
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText - 2,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "#c7c7c7",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Carlos actively participates in the open-source community, even creating a React.js library to help AI developers build data-intensive applications. In his free time, he enjoys riding his electric unicycle and designing Bluetooth mesh light shows for rock concerts.
                                        </Text>
                                        <br />

                                        {/* <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Try it out
                                        </TouchableOpacity> */}

                                    </View>
                                </Col>
                            </Row>
                        </div>

                    </div>

                    <div className="AboutContainer container-fluid"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            paddingBottom: 80,
                            backgroundColor: "#202125",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            // backgroundColor: "#3c4043",

                            paddingTop: "70px"
                        }}>

                        
                        <div className="VerticalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "20px",
                        }}>

                            <div style={{
                                display: "flex",
                                gap: 30,
                                flexWrap: "wrap"
                            }}>
                                <img src="https://i.imgur.com/f0IutJy.png"
                                    width="150px" height="150px" />

                                <img src="https://i.imgur.com/NiEfegu.png"
                                    width="150px" height="150px" />

                                <img src="https://i.imgur.com/vwL2M9N.png"
                                    width="150px" height="150px" />

                                <img src="https://i.imgur.com/wGVC8wY.png"
                                    width="150px" height="150px" />

                                <img src="https://i.imgur.com/FEBiVe9.png"
                                    width="150px" height="150px" />
                            </div>

                        </div>

                    </div>


                    {/* <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "40px"
                        // justifyContent: "center",
                    }}>
                        <TouchableOpacity style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#8ab4f8",
                            padding: "20px",
                            borderRadius: "40px",
                            gap: "15px"
                        }} onPress={() => this.sendButtonPressed()}>
                            <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                width="30" />
                            <p className="SubmitText" style={{
                                backgroundColor: "#8ab4f8", color: "black",
                                paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                paddingRight: "0px"
                            }}>Read Our Story</p>
                        </TouchableOpacity>



                    </div>
 */}
                    <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />


                    {/* <FooterStealth backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF"
                        showLinkedIn={true} showTwitter={true} showYoutube={false} showCallUs={false}
                        showTalkWithFounders={false} showPrivacyPolicy={false} showTerms={false}
                        showBlog={false} showSalesEmail={true} /> */}
                </div>
            );
        } else {
            return null;
        }
    }

    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <Modal style={styles.mainConfirmationView}>
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                        }}>
                        <Text
                            style={{
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 90,
                                marginTop: 50,
                            }}>
                            The Future of Email is Here
                        </Text>
                        <Image style={styles.checkImage} source={require("./img/check_circle.svg")} resizeMode="cover" />
                        <Text
                            style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 18,
                                marginTop: 30,
                            }}>
                            Thank you!!
                        </Text>
                        <Text style={styles.youSigned}>Your email was successfully submitted.</Text>
                        <Text style={styles.wewill}>We will add you to the waitlist</Text>
                        <View style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></View>
                        {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText">Addy AI</p>
                        </div>
                        <br />
                        <br />
                    </View>

                    {/* <Image
                      style={styles.confirmationImage}
                      source={this.state.imageSrc || require('./img/frame2.png')}
                      resizeMode='cover'
                    /> */}
                </Modal>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                {this.Showconfirmation()}
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <AboutUsStealth />;
}
