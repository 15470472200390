// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions, Image, TouchableOpacity, View } from "react-native";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { firestore } from "../firebase.js";
import Header from "../header";
import Footer from "../footer";
import { v4 as uuidv4 } from 'uuid';

import { ref, set, update, get } from "firebase/database";
import { database } from "../firebase.js";

import "./toggle.css";
// Load Stripe
import { loadStripe } from "@stripe/stripe-js";
import { updateDimensions, styles } from "../styles";
import HeaderDark from "../headerDark.js";
import PricingComparison from "../components/pricingComparison/pricingComparison.js";
import PricingFAQ from "./pricingFAQ.js";

let stripe = null;
const STRIPE_API_URL = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/stripeUtils/stripe"

const visitId = uuidv4();
class Pricing extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const uid = urlParams.get("uid") == null ? undefined : urlParams.get("uid");
        const checkout = urlParams.get("checkout") == null ? undefined : urlParams.get("checkout");

        const clientId = urlParams.get("clientId") == null ? undefined : urlParams.get("clientId");

        const visitSource = urlParams.get("src");
        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters
        const emailAddressSource = urlParams.get("email") == null ? null : urlParams.get("email");

        if (clientId) {
            // Send a message to the server to send a web socket message, to 
            // that specific client that request was successful or failed.
            const fetchURL = `https://addy-web-socket-zquodzeuva-uc.a.run.app/send/${clientId}`;
            // Define the post request
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: "empty" })
            };

            // Make the request
            fetch(fetchURL, requestOptions)
                .then(response => response)
                .then(data => console.log(data))
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

        // Log this visit
        this.logVisit(formattedVisitSource, emailAddressSource);

        if (checkout) {
            // Stripe redirect immediate to checkout session
            this.loadStripeAndGoToCheckout(checkout);
        }

        this.state = {
            successMessage: "",
            newUpdates: {},
            recurring: "monthly",
            uid: uid,
            source: formattedVisitSource,
            emailAddressSource: emailAddressSource,
            timeLeft: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
        };

    }

    updateDimensions() {
        updateDimensions();
        this.setState();
    }

    componentDidMount() {
        this.updateDimensions();
        this.loadStripe();

        window.addEventListener("resize", this.updateDimensions.bind(this));

        // this.endTime = Date.now() + 7 * 24 * 60 * 60 * 1000; // 7 days from now
        this.endTime = 1707785108000;
        this.updateTimer();
        this.interval = setInterval(this.updateTimer, 1000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    logVisit(source, emailAddressSource = null) {
        if (window.location.hostname.includes("localhost")) return;
    
        const date = Date.now();

        var visitRef = ref(database, `Analytics/SiteVisit/${source}/${visitId}`);
        set(visitRef, {
            date: date,
            id: visitId,
            emailAddressSource: emailAddressSource ?
                emailAddressSource : "null",
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/VisitCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    async loadStripeAndGoToCheckout(sessionId) {
        stripe = await loadStripe(
            "pk_live_51Mn5GDAaf2OpP5o1qHlPYyxfcqKT9z69JQNynq0f3rPvdoOnZNtJYM3BOX9vJniBdvW5SYrNfqAv3vBPyutZsNeU00BYN8d9bL"
        );
        stripe.redirectToCheckout({ sessionId: sessionId });
    }

    async loadStripe() {
        stripe = await loadStripe(
            "pk_live_51Mn5GDAaf2OpP5o1qHlPYyxfcqKT9z69JQNynq0f3rPvdoOnZNtJYM3BOX9vJniBdvW5SYrNfqAv3vBPyutZsNeU00BYN8d9bL"
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() { }

    onSubscribeClick = async (priceID, planID, buttonId) => {


        const uid = this.state.uid;
        if (uid && uid != undefined && uid != null) {
            this.stripeCreateCheckoutSession(uid, planID, buttonId);
        } else {
            // No UID redirect to stripe with email
            const checkoutParams = {
                mode: "subscription",
                successUrl: "https://addy.so/subscribed",
                cancelUrl: "https://addy.so/pricing?ref=pricing-from-stripe-cancel",
                lineItems: [
                    {
                        price: priceID,
                        quantity: 1,
                    },
                ],
            }

            if (window.Rewardful && window.Rewardful.referral) {
                alert("Referral: " + window.Rewardful.referral)
                checkoutParams.clientReferenceId = window.Rewardful.referral;
            } else {
                alert("No referral");
            }

            stripe.redirectToCheckout(checkoutParams);
        }


    };

    stripeCreateCheckoutSession = async (uid, planId, buttonId) => {

        const subscribeButton = document.getElementById(buttonId);
        if (subscribeButton) {
            subscribeButton.innerHTML = `Get started <div class="loader-new"></div>`;
        }

        let checkoutSessionEndpoint = `${STRIPE_API_URL}/checkout-session?uid=${uid}&planID=${planId}`;
        if (window.Rewardful && window.Rewardful.referral) {
            alert("Referral: " + window.Rewardful.referral)
            checkoutSessionEndpoint += `&referral=${window.Rewardful.referral}`;
        } else {
            alert("No referral");
        }

        const sessionId = await this.fetchResponseDataGet(checkoutSessionEndpoint, "sessionID");

        if (subscribeButton) {
            subscribeButton.innerHTML = `Get started`;
        }

        if (!sessionId) {
            alert("Sorry something went wrong. Please try again later");
            return;
        }

        stripe.redirectToCheckout({ sessionId: sessionId });

    };

    renderNewUpdates = (updates) => {
        const version = updates.version;
        const newUpdates = updates.updates || [];

        const renderHTML = [];
        renderHTML.push(<h4 style={{ color: "#2B1C50", marginBottom: "15px" }}>What's new in v{version} &#127873;</h4>);

        for (let i = 0; i < newUpdates.length; i++) {
            renderHTML.push(
                <div className="HorizontalFlex CenterOppositeDirectionToo">
                    <p style={{ fontSize: "22px", margin: "0px", marginRight: "12px" }}>&#9900;</p>
                    <p style={{ fontSize: "22px", margin: "0px" }}>{newUpdates[i]}</p>
                </div>
            );
        }
        return renderHTML;
    };
    openHowTo = () => {
        // Log this click
        window.open("https://addy.so/how-to-upgrade", "_blank");
    };

    contactUsPressed = () => {
        window.open("https://calendly.com/michael-addy-ai/addy-ai-demo", "_blank");
    };

    fetchResponseDataGet = async (endpoint, attributeToget) => {
        let returnVal;
        await fetch(endpoint, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    returnVal = data[attributeToget];
                } else {
                    returnVal = undefined;
                }
            }).catch(() => {
                returnVal = undefined;
            });
        return returnVal;
    }

    handleRadioButtonChange = (recurring) => {
        const yearlyButton = document.getElementById("yearly");
        const monthlyButton = document.getElementById("monthly");

        if (recurring == "yearly") {
            monthlyButton.style.background = "rgba(255, 255, 255, 0.1)";
            monthlyButton.style.color = "rgba(255, 255, 255, 1)";

            yearlyButton.style.background = "#735DDD";
            yearlyButton.style.color = "#FFFFFF";
            // Update state
            this.setState({
                recurring: recurring,
            });
        } else {
            // Monthly
            yearlyButton.style.background = "rgba(255, 255, 255, 0.1)";
            yearlyButton.style.color = "rgba(255, 255, 255, 1)";

            monthlyButton.style.background = "#735DDD";
            monthlyButton.style.color = "#FFFFFF";
            this.setState({
                recurring: recurring,
            });
        }
    };

    updateTimer = () => {
        const currentTime = Date.now();
        const distance = this.endTime - currentTime;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            clearInterval(this.interval);
            this.setState({ timeLeft: { days: 0, hours: 0, minutes: 0, seconds: 0 } });
            // Handle completion, perhaps notify the user that the countdown is finished
        } else {
            this.setState({ timeLeft: { days, hours, minutes, seconds } });
        }
    };

    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };

    MainPage() {
        const { days, hours, minutes, seconds } = this.state.timeLeft;

        return (
            <div
                div
                className="AboutContainer container"
                style={{
                    width: "100%",
                    paddingLeft: styles.basic.blueBgPaddingLeft / 2,
                    paddingRight: styles.basic.blueBgPaddingLeft / 2,
                    justifyContent: "center",
                    // marginBottom: 100,
                    paddingTop: 120,
                    backgroundColor: "rgb(32, 33, 36)"
                }}>
                {/* <div className="badge" style={{
                    width: "100%",
                    maxWidth: "100%",
                    textWrap: "wrap",
                    overflowWrap: "break-word",
                    padding: "15px",
                    borderRadius: "9px",
                    marginBottom: "15px",
                    fontSize: "20px",
                    color: "#000000",
                }}>
                    Limited Time Offer: 60% OFF on all plans! <mark style={{
                        background: "transparent",
                        color: "#745dde"
                    }}>{days}d {hours}h {minutes}m {seconds}s</mark>
                </div> */}
                <h1 style={{
                    color: "#FFFFFF",
                    fontSize: "40px",
                }} className="pricing-header">Simple, transparent pricing!</h1>
                <p style={{
                    ...styles.headerSubtitle,
                    color: "rgba(255, 255, 255, 0.8)"
                }}>Unlock the smartest email experience ever made</p>
                {/* <p className="pricing-sub-helper">
                    
                </p> */}
                {/* <p className="subtitle-pricing-text">
                    Our <mark style={{ backgroundColor: "transparent", fontWeight: "bold" }}>FREE</mark> plan gives you 3 email
                    generations per day with <mark style={{ backgroundColor: "transparent", fontWeight: "bold" }}>ALL</mark>{" "}
                    Unlimited features!
                </p> */}

                <br />
                {/* Toggle between monthly and yearly */}
                <div className="toggle-div">
                    <div class="switch">
                        <p id="monthly" onClick={() => this.handleRadioButtonChange("monthly")}>
                            Monthly
                        </p>
                        <p style={{
                            color: "#FFFFFF",
                            backgroundColor: "rgba(255, 255, 255, 0.1)"
                        }} id="yearly" onClick={() => this.handleRadioButtonChange("yearly")}>
                            Annual
                        </p>
                    </div>
                </div>

                <br />

                {this.state.recurring == "monthly" && (
                    <div className="pricing-main-div" id="monthly-pricing">
                        <Row style={{ width: "100%", paddingTop: 30 }}>
                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div class="columns border-box plan-container">
                                    <div className="fake-skeleton-box">
                                        <p className="highlighting-text"></p>
                                    </div>
                                    <p className="plan-title">Free</p>
                                    <p className="plan-type">
                                        No personalization
                                    </p>
                                    <p className="plan-summary">
                                        3 free AI-generated emails per day, powered by GPT 3.5, with limited features.
                                    </p>
                                    <p className="price-amount">
                                        $ 0 <mark class="price-interval">/ month</mark>
                                    </p>
                                    <div
                                        className="subscribe-button"
                                        onClick={() => {
                                            window.open(
                                                "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
                                                "_blank"
                                            );
                                        }}>
                                        {this.state.uid ? "Get started" : "Get started"}
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; 3 emails per day</li>
                                        <li className="price-feature">&#9989; Powered by GPT 3.5</li>
                                        <li className="price-feature">&#9989; Compose, Reply, and Follow-up emails</li>
                                        {/* <li className="price-feature">&#9989; AI Reply emails</li>
                                        <li className="price-feature">&#9989; AI Follow up emails</li> */}
                                        <li className="price-feature">&#9989; Access 10 tones</li>
                                        <li className="price-feature">&#9989; Control generated email length</li>
                                        <li className="price-feature">&#9989; Add your own prompts</li>
                                        <li className="price-feature">&#9989; Rewrite any part of the email</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div className="columns border-box plan-container best-deal-container">
                                    <div className="skeleton-box">
                                        <p className="highlighting-text">&#129321; Best deal</p>
                                    </div>

                                    <p className="plan-title">Unlimited</p>

                                    <p className="plan-type">
                                        Best for personal, professional, academic emails.
                                    </p>

                                    <p className="plan-summary">
                                        Unlimited emails, much smarter A.I, and personalized emails in all major languages!
                                    </p>

                                    <p className="price-amount-off">$ 19 <mark className="badge" style={{
                                        background: "transparent",
                                        textDecoration: "none",
                                        backgroundColor: "#FDE047",
                                        color: "#1F2937",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        marginLeft: "5px"
                                    }}>Save 60%</mark></p>

                                    <p className="price-amount">
                                        $ 7.60 <mark className="price-interval">/ month</mark>
                                    </p>

                                    <div
                                        className="subscribe-button"
                                        id="unlimited-monthly-btn"
                                        onClick={() => {
                                            this.onSubscribeClick("price_1ObXn0Aaf2OpP5o15JgzQ4rk", "unlimited-monthly", "unlimited-monthly-btn");
                                            
                                        }}>
                                        Get started
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; Everything in Free</li>
                                        <li className="price-feature">&#9989; Powered by GPT-4</li>
                                        <li className="price-feature">&#9989; Unlimited emails per day</li>
                                        <li className="price-feature">&#9989; Emails personalized to sound like you</li>
                                        <li className="price-feature">&#9989; All major languages</li>
                                        <li className="price-feature">&#9989; Suggested replies</li>
                                        <li className="price-feature">&#9989; Summarize emails & attachments</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div className="columns border-box plan-container">
                                    <div className="fake-skeleton-box">
                                        <p className="highlighting-text"></p>
                                    </div>
                                    <p className="plan-title">Enterprise</p>
                                    <p className="plan-type">
                                        Best for professional teams in the same company.
                                    </p>
                                    <p className="plan-summary">
                                        We will train a custom AI that understands your business type, culture, and brand voice! &nbsp; &nbsp;
                                        &nbsp; &nbsp;
                                    </p>
                                    <p className="price-amount">
                                        &nbsp;
                                    </p>
                                    <div className="subscribe-button" onClick={() => this.contactUsPressed()}>
                                        Contact Us
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; Everything in Unlimited</li>
                                        <li className="price-feature">&#9989; Train GPT-4 and custom models for your business</li>
                                        <li className="price-feature">&#9989; Unlimited emails per day</li>
                                        <li className="price-feature">&#9989; Train AI to understand your brand voice</li>
                                        <li className="price-feature">&#9989; Background-generated emails</li>
                                        <li className="price-feature">&#9989; Shared knowledge base with your team</li>
                                        <li className="price-feature">&#9989; Early access to new features</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}

                {this.state.recurring == "yearly" && (
                    <div className="pricing-main-div" id="yearly-pricing">
                        <Row style={{ width: "100%", paddingTop: 30 }}>
                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div class="columns border-box plan-container">
                                    <div className="fake-skeleton-box">
                                        <p className="highlighting-text"></p>
                                    </div>
                                    <p className="plan-title">Free</p>
                                    <p className="plan-type">
                                        No personalization
                                    </p>
                                    <p className="plan-summary">
                                        3 free AI-generated emails per day, powered by GPT 3.5, with limited features.
                                    </p>
                                    {/* <p className="price-amount-off">$ 0</p> */}
                                    <p className="price-amount">
                                        $ 0 <mark class="price-interval">/ year</mark>
                                    </p>
                                    <div
                                        className="subscribe-button"
                                        onClick={() => {
                                            window.open(
                                                "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
                                                "_blank"
                                            );
                                        }}>
                                        {this.state.uid ? "Get started" : "Get started"}
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; 3 emails per day</li>
                                        <li className="price-feature">&#9989; Powered by GPT 3.5</li>
                                        <li className="price-feature">&#9989; Compose, Reply, and Follow-up emails</li>
                                        {/* <li className="price-feature">&#9989; AI Reply emails</li>
                                        <li className="price-feature">&#9989; AI Follow up emails</li> */}
                                        <li className="price-feature">&#9989; Access 10 tones</li>
                                        <li className="price-feature">&#9989; Control generated email length</li>
                                        <li className="price-feature">&#9989; Add your own prompts</li>
                                        <li className="price-feature">&#9989; Rewrite any part of the email</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div className="columns border-box plan-container best-deal-container">
                                    <div className="skeleton-box">
                                        <p className="highlighting-text">&#129321; Best deal</p>
                                    </div>

                                    <p className="plan-title">Unlimited</p>
                                    <p className="plan-type">
                                        Best for personal, professional, academic emails.
                                    </p>
                                    <p className="plan-summary">
                                        Unlimited emails, much smarter A.I, and personalized emails in all major languages!
                                    </p>
                                    <p className="price-amount-off">$ 182 <mark className="badge" style={{
                                        background: "transparent",
                                        textDecoration: "none",
                                        backgroundColor: "#FDE047",
                                        color: "#1F2937",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        marginLeft: "5px"
                                    }}>Save 60%</mark></p>
                                    <p className="price-amount">
                                        $ 72 <mark className="price-interval">/ year</mark>
                                    </p>

                                    <div
                                        className="subscribe-button"
                                        id="unlimited-annual-btn"
                                        onClick={() => {
                                            this.onSubscribeClick("price_1ObXokAaf2OpP5o1cG4WG2RI", "unlimited-annual", "unlimited-annual-btn");
                                            
                                        }}>
                                        Get started
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; Everything in Free</li>
                                        <li className="price-feature">&#9989; Powered by GPT-4</li>
                                        <li className="price-feature">&#9989; Unlimited emails per day</li>
                                        <li className="price-feature">&#9989; Emails personalized to sound like you</li>
                                        <li className="price-feature">&#9989; All major languages</li>
                                        <li className="price-feature">&#9989; Suggested replies</li>
                                        <li className="price-feature">&#9989; Summarize emails & attachments</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col
                                md="4"
                                style={{ display: "flex", flexDirection: "column", alignItems: "center", alignItems: "center" }}>
                                <div className="columns border-box plan-container">
                                    <div className="fake-skeleton-box">
                                        <p className="highlighting-text"></p>
                                    </div>
                                    <p className="plan-title">Enterprise</p>
                                    <p className="plan-type">
                                        Best for professional teams in the same company.
                                    </p>
                                    <p className="plan-summary">
                                        We will train a custom AI that understands your business type, culture, and brand voice! &nbsp; &nbsp;
                                        &nbsp; &nbsp;
                                    </p>
                                    {/* <p className="price-amount-off">&nbsp;</p> */}
                                    <p className="price-amount">
                                        &nbsp;
                                    </p>
                                    <div className="subscribe-button" onClick={() => this.contactUsPressed()}>
                                        Contact Us
                                    </div>
                                    <ul className="price">
                                        <li className="price-feature">&#9989; Everything in Unlimited</li>
                                        <li className="price-feature">&#9989; Train GPT-4 and custom models for your business</li>
                                        <li className="price-feature">&#9989; Unlimited emails per day</li>
                                        <li className="price-feature">&#9989; Train AI to understand your brand voice</li>
                                        <li className="price-feature">&#9989; Background-generated emails</li>
                                        <li className="price-feature">&#9989; Shared knowledge base with your team</li>
                                        <li className="price-feature">&#9989; Early access to new features</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }

    Reviews() {
        return <div
            className="AboutContainer container"
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                paddingTop: 60,
                paddingBottom: 20,
                paddingLeft: "0px",
            }}>

            <h1 style={{
                color: "#FFFFFF",
                fontSize: "40px",
            }} className="pricing-header">Your productivity hack</h1>
            <p style={{
                ...styles.headerSubtitle,
                color: "rgba(255, 255, 255, 0.8)"
            }}>Don't take our word for it. Addy is loved, and highly rated by customers from across the globe</p>
            <br />
            <div className="row" style={{ marginTop: "30px", padding: 0 }}>



                <div className="col-lg-4 justify-content-center">

                    <div>
                        <div style={{ height: "300px" }}>
                            <iframe id="-Nci3gsVHvQUn9GHJzBq" src="https://embed-v2.testimonial.to/share/-Nci3gsVHvQUn9GHJzBq" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                        </div>
                        {/* <div style={{ height: "300px" }}>
                        <iframe id="-Nci3qKs832jDfZReW3_" src="https://embed-v2.testimonial.to/share/-Nci3qKs832jDfZReW3_" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                    </div>

                </div>


                <div className="col-lg-4 justify-content-center">

                    <div>
                        <div style={{ height: "350px" }}>
                            <iframe id="-Nci485hCQyeelwznqxu" src="https://embed-v2.testimonial.to/share/-Nci485hCQyeelwznqxu" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                        </div>
                        {/* <div style={{ height: "430px" }}>
                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                    </div>




                </div>

                <div className="col-lg-4 justify-content-center">



                    <div>
                        <div style={{ height: "300px", }}>
                            <iframe id="-N_jglbWrXdObHILrny4" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                        </div>
                        {/* <div style={{ height: "300px" }}>
                        <iframe id="-Nci61gJFakb1B4GSr7r" src="https://embed-v2.testimonial.to/share/-Nci61gJFakb1B4GSr7r" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                    </div> */}
                    </div>
                </div>

            </div>

        </div>
    }

    getStartedFromComparisonClick = (tier) => {
        if (tier == "free") {
            window.open(
                "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
                "_blank"
            );
        } else if (tier == "unlimited") {
            this.onSubscribeClick("price_1ObXn0Aaf2OpP5o15JgzQ4rk", "unlimited-monthly", "unlimited-comparison-button");
        } else if (tier == "enterprise") {
            window.open(
                "https://calendly.com/michael-addy-ai/addy-ai-demo",
                "_blank"
            );
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: "#202125", }}>
                <HeaderDark />


                {this.MainPage()}

                <div style={{
                    marginTop: "40px"
                }}>
                    <h1 style={{
                        color: "#FFFFFF",
                        fontSize: "40px",
                    }} className="pricing-header">Compare plans</h1>
                    <p style={{
                        ...styles.headerSubtitle,
                        color: "rgba(255, 255, 255, 0.8)"
                    }}>Our paid plans let you train a personalized Email AI that sounds like you</p>
                    <PricingComparison getStartedClick={this.getStartedFromComparisonClick} />
                </div>

                <div style={{
                    backgroundColor: "rgba(211, 211, 211, 0.15)",
                    marginTop: "50px"
                }}>
                    {this.Reviews()}
                </div>

                <div className="AboutContainer container">
                    <PricingFAQ />
                </div>


                <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />

                {/* <Footer backgroundColor="#3c4043" textColor="#FFFFFF" iconColor="#FFFFFF" /> */}
            </div>
        );
    }
}

export default Pricing;
