// TikTok.js
import React, { useState, useEffect } from 'react';
import { updateDimensions, styles } from "./../styles";
import { tiktokstyles } from './tiktokstyles';

import Footer from "./../footer";
import { Image } from "react-native-web";
import { apiURL } from "./../globalVariables";

import demoImage from "../img/email_main_demo.gif";

import { auth } from '../firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "./style.css";
import HeaderDark from '../headerDark';

let smallScreen = window.innerWidth <= 768 ? true : false;

const TikTokForm = ({ onRedeem }) => {
    const [errorMessage, setErrorMessage] = useState('');

    // Add this useEffect
    useEffect(() => {

        setTimeout(() => {
            modifySignUpMessagingToMakeItClearer();
        }, 1000);

    }, []);


    function modifySignUpMessagingToMakeItClearer() {
        const firebaseNextButton = document.querySelector(".firebaseui-id-submit");
        if (firebaseNextButton && firebaseNextButton.innerHTML == "Next") {
            firebaseNextButton.style.width = "100%";
            firebaseNextButton.style.transition = "0.3s all ease";
        }
    }



    // FirebaseUI configuration
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                onRedeem(authResult.user.displayName || '', authResult.user.uid || '');
                return false; // Prevents redirect after sign-in
            },
        },
    };
    let orientation = window.innerWidth <= 768 ? { flexDirection: 'column' } : { flexDirection: 'row' }


    console.log({ orientation })
    return (
        <div style={{ ...tiktokstyles(smallScreen).contentContainer, ...orientation, }}>
            <div style={{ ...tiktokstyles(smallScreen).blurbContainer, }}>
                <h1 style={tiktokstyles(smallScreen).header}>
                    Redeem your one-year subscription purchased on TikTok
                </h1>
                <div style={tiktokstyles(smallScreen).description}>
                    Addy is an AI email assistant that can be trained to compose and reply to your emails for you, saving you up to 3 hours a day.
                </div>
                <div className="HorizontalFlex CenterOppositeDirectionToo" style={{
                    justifyContent: "left",
                    marginTop: "30px"
                }}>
                    <img alt="sample" width="160" height="40" src="https://i.imgur.com/xW3XP3n.png" />
                    <p style={{
                        marginLeft: "10px",
                        color: "rgba(255, 255, 255, 0.8)",
                        width: "fit-content",
                        margin: "0px"
                    }}>Trusted by over 10,000 people</p>
                </div>
            </div>
            <div style={{ ...tiktokstyles(smallScreen).formContainer }}>
                <div style={tiktokstyles(smallScreen).formHeader}>
                    Sign up or log in to redeem your purchase
                </div>
                <div style={tiktokstyles(smallScreen).formSubtext}>
                    We'll check if you have an Addy account, and help create one if you don't.
                </div>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                <div style={tiktokstyles(smallScreen).terms}>
                    By continuing, you are indicating that you accept our
                    <a href="/terms" style={{ textDecoration: 'underline', color: 'rgba(5, 5, 5, 0.57)', }}> Terms of Service </a>
                    and
                    <a href="/privacy" style={{ textDecoration: 'underline', color: 'rgba(5, 5, 5, 0.57)', }}> Privacy Policy</a>.
                </div>
            </div>
        </div>
    );
};

const Page = ({ children }) => {
    return (
        <div style={tiktokstyles(smallScreen).tiktokContainer}>
            <HeaderBar />
            <div></div>
            {children}
            <FooterBar />
        </div>
    );
};

const TikTok = () => {
    const [view, setView] = useState('form');
    const [userName, setUserName] = useState('');
    const [uid, setUid] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Handlers to update the state to the correct view
    const showRedeem = (name, uid) => {
        setUserName(name);
        setUid(uid);
        setView('redeem');
    };

    const showSuccess = () => setView('success');

    return (
        <Page>
            {view === 'form' && <TikTokForm onRedeem={showRedeem} />}
            {view === 'redeem' && <TikTokRedeem onSuccess={showSuccess} name={userName} uid={uid} />}
            {view === 'success' && <TikTokSuccess />}
        </Page>
    );
};

export default TikTok;


const TikTokRedeem = ({ onSuccess, name, uid }) => {
    const [code, setCode] = useState(Array(8).fill(''));
    const [errorMessage, setErrorMessage] = useState('');

    const handleBackspacePressed = (event, index) => {
        const { value } = event.target;
        // If Backspace is pressed and the input element is empty, focus on the previous one

        if (event.key === 'Backspace' && index > 0) {
            const newCode = [...code];
            newCode[index] = "";
            setCode(newCode);
            document.getElementById(`code-input-${index - 1}`).focus();
        }
    }

    const handleCodeChange = (event, index) => {
        const { value } = event.target;

        const newCode = [...code];
        newCode[index] = value.slice(-1); // Ensure single character
        setCode(newCode);

        console.log("Current code", newCode);

        // Automatically focus the next input when a character is typed
        if (value && index < 7) {
            document.getElementById(`code-input-${index + 1}`).focus();
        }

    };

    const handleVerify = async () => {
        // Combine code array into a single string
        const fullCode = code.join('');
        const verifyButton = document.querySelector("#verify-button");
        const previousButtonText = verifyButton.innerHTML;

        if (verifyButton) {
            verifyButton.innerHTML = `${previousButtonText} <div class="loader-new"></div>`;
        }

        try {
            const requestURL = `https://us-central1-hey-addy-chatgpt.cloudfunctions.net/api/user/redeem-tiktok-purchase`;
            const response = await fetch(requestURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        "code": fullCode,
                        "userID": uid
                    }),
            });

            const data = await response.json();
            console.log("Response", data);
            if (data.success) {
                verifyButton.innerHTML = previousButtonText;
                onSuccess();
            } else {
                setErrorMessage(data.message || 'Invalid Code. Please try again.');
                verifyButton.innerHTML = previousButtonText;
                setTimeout(() => {
                    setErrorMessage(""); // reset the error field
                }, 5000);
            }
        } catch (error) {
            verifyButton.innerHTML = previousButtonText;
            console.error('There was an error!', error);
            setErrorMessage('An error occurred. Please try again later.');
            setTimeout(() => {
                setErrorMessage(""); // reset the error field
            }, 5000);
        }
    };

    let orientation = window.innerWidth <= 768 ? 'column' : 'row'
    console.log({ orientation })
    return (
        <div style={{
            ...tiktokstyles(smallScreen).contentContainer,
            flexDirection: orientation
        }}>
            <div style={tiktokstyles(smallScreen).blurbContainer}>
                <div style={tiktokstyles(smallScreen).header}>
                    Welcome {name} 👋
                </div>
                <div style={tiktokstyles(smallScreen).description}>
                    Please verify your purchase.
                </div>
                <div style={tiktokstyles(smallScreen).description}>
                    Verify your purchase by entering the 8-character code found in the package we shipped to you.
                </div>

                <div>
                    <img height="auto" width={smallScreen ? '300px' : '500px'} src='https://i.imgur.com/inVmbpi.png' />
                </div>

            </div>
            <div style={tiktokstyles(smallScreen).formContainer}>
                <div style={tiktokstyles(smallScreen).formHeader}>
                    Enter your purchase code below
                </div>
                <div style={tiktokstyles(smallScreen).formSubtext}>
                    Your code is unique to you and can only be used once.
                </div>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>

                    {code.map((c, index) => (
                        <input

                            key={index}
                            id={`code-input-${index}`}
                            style={tiktokstyles(smallScreen).emailInput}
                            type="text"
                            value={c}
                            onChange={(e) => handleCodeChange(e, index)}
                            onKeyDown={(e) => handleBackspacePressed(e, index)}
                        />
                    ))}
                </div>
                <button className="campaign-button" id='verify-button' style={tiktokstyles(smallScreen).submitButton} onClick={handleVerify}>
                    VERIFY
                </button>
                <div style={tiktokstyles(smallScreen).terms}>
                    By continuing, you are indicating that you accept our
                    <a href="/terms" style={{ textDecoration: 'underline', color: 'rgba(5, 5, 5, 0.57)', }}> Terms of Service </a>
                    and
                    <a href="/privacy" style={{ textDecoration: 'underline', color: 'rgba(5, 5, 5, 0.57)', }}> Privacy Policy</a>.
                </div>
            </div>
        </div>
    );
};

const TikTokSuccess = () => {
    const [code, setCode] = useState('');

    const handleCodeChange = (e) => setCode(e.target.value);

    const goToExtension = () => {
        window.open('https://chromewebstore.google.com/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn', '_blank');
        window.location.href = '/email/faq';
    };

    return (
        <div style={tiktokstyles(smallScreen).contentContainer} className='row'>
            <div style={tiktokstyles(smallScreen).blurbContainer} className='col-md-6'>
                <div style={tiktokstyles(smallScreen).header}>
                    Verified Successfully ✅
                </div>
                <div style={tiktokstyles(smallScreen).description}>
                    Please download the Addy Chrome Extension to start using the smartest email experience ever made.
                </div>
                <div className="HorizontalFlex CenterOppositeDirectionToo" style={{
                    justifyContent: "left",
                    marginTop: "30px"
                }}>
                    <img alt="sample" width="160" height="40" src="https://i.imgur.com/xW3XP3n.png" />
                    <p className="footer-helper" style={{
                        marginLeft: "10px",
                        color: "rgba(255, 255, 255, 0.8)",
                        width: "fit-content",
                        margin: "0px"
                    }}>Trusted by over 10,000 people</p>
                </div>
            </div>

            <div style={tiktokstyles(smallScreen).formContainer} className='col-md-6'>
                <div style={{ ...tiktokstyles(smallScreen).formHeader, display: 'flex', flexDirection: 'row' }}>
                    Download the Chrome Extension
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: "20px"}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", }}>
                        <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg" width="50px" style={{ marginRight: '15px', height: "fit-content" }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: 'black', fontWeight: 700, fontSize: '20px' }}>Addy</div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>⭐⭐⭐⭐⭐ </div>
                                <div style={{ ...tiktokstyles(smallScreen).terms, marginTop: 0, paddingLeft: '15px', textDecoration: 'none', display: "block" }}>• 10,000+ users</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className="VerticalFlex"
                    style={{ alignItems: "center", marginBottom: "30px"}}>
                    <img className="MainImagePromoElevated"
                        src={demoImage}
                        style={{
                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            // width: (styles.basic.midImageWidth).toString() + "px",
                            // height: (styles.basic.midImageWidth).toString() + "px",
                            borderRadius: "20px",
                            objectFit: "cover",
                        }} />
                </div>

                <button className="campaign-button" style={tiktokstyles(smallScreen).submitButton} onClick={goToExtension}>
                    ADD TO CHROME
                </button>
                <div style={{
                    ...tiktokstyles(smallScreen).terms,
                    display: "block", textDecoration: "none"
                }}>
                    #1 Chrome Extension For Email Productivity
                </div>
            </div>
        </div >
    );
};



const HeaderBar = () => {
    const onAppLogoClick = () => {
        // Implement your logic here
        console.log('App logo clicked');
    };
    return (
        <HeaderDark />
    );
};
const FooterBar = () => {
    return (
        <div className="AboutContainer container-fluid"
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#202125",
                backgroundColor: "rgba(255, 255, 255, 01)",
                paddingTop: "70px",
                paddingRight: "0px"
            }}>
            <p style={{
                ...styles.headerTextDark,
                fontSize: styles.basic.titleText - 9,
                fontFamily: "SF-Pro-Display-Regular",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: "normal",
                marginBottom: "15px",
            }}>
                Proudly backed by
            </p>

            <div className="VerticalFlex CenterContentsCompletely" style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingBottom: "70px",
                width: "100%",
                gap: "20px",
            }}>
                <div style={{
                    display: "flex",
                    gap: 30,
                    flexWrap: "wrap"
                }}>
                    <img src="https://i.imgur.com/v33lYtV.png"
                        width="360px" height="auto" alt="Image 1" />

                    <img src="https://i.imgur.com/AR2u6gb.png"
                        width="360px" height="auto" alt="Image 2" />
                </div>

                <p style={{
                    color: "rgba(0, 0, 0, 0.7)",
                    fontFamily: "SF-Pro-Display-Light",
                    fontSize: "22px",
                    width: "360px",
                    textAlign: "left"
                }}>
                    And notable Angels - Merline Saintil
                </p>
            </div>

            <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />
        </div>
    );
};
