// App.js - WEB
import React, { Component } from "react";
import { FaInstagram, FaLinkedin, FaTiktok, FaTwitter, FaYoutube, } from "react-icons/fa";
import { updateDimensions, styles } from "./styles";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: props.backgroundColor ? props.backgroundColor : undefined,
            textColor: props.textColor ? props.textColor : undefined,
            iconColor: props.iconColor ? props.iconColor : undefined,
        };
    }
    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        const headerElement = document.getElementById("addy-main-header");
        window.addEventListener("scroll", () => {
            const scrollPosition = document.documentElement.scrollTop;

            if (scrollPosition > 200) {
                headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
            } else {
                headerElement.style.boxShadow = "none";
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() { }

    MainPage() {
        return (
            <div
                className="FooterContainer row"
                style={{
                    width: "100vw",
                    height: "fit-content",
                    paddingHorizontal: 40,
                    paddingTop: "50px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // alignItems: "center",
                    backgroundColor: this.state.backgroundColor ? this.state.backgroundColor : "#f7faff",
                }}>

                <div className="col-md-3" style={{ paddingBottom: "40px", paddingLeft: "50px" }}>
                    <div>
                        <p
                            className="OverflowBreak"
                            style={{
                                fontSize: "30px",
                                fontWeight: "800",
                                color: "#FFFFFF",
                                textAlign: "left",
                                margin: "0px",
                                fontFamily: "SF-Pro-Display-Bold",
                            }}>
                            Addy
                        </p>
                    </div>

                    <div>
                        <p
                            className="OverflowBreak"
                            style={{
                                fontSize: "18px",
                                marginBottom: "15px",
                                fontWeight: "bold",
                                color: "#e9eaee",
                                fontFamily: "SF-Pro-Rounded-Light",
                            }}>
                            The smartest email experience ever made
                        </p>
                    </div>

                    <div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#8ab4f8",
                            padding: "20px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            borderRadius: "40px",
                            gap: "10px",
                            width: "fit-content"
                        }}>
                            <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                width="20" />
                            <a className="SubmitText"
                                href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn"
                                target="_blank"
                                style={{
                                    textDecoration: "none",
                                    backgroundColor: "#8ab4f8", color: "black",
                                    paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "16px",
                                    paddingRight: "0px"
                                }}>Get started</a>
                        </div>
                    </div>

                </div>

                <div className="col-md-3" style={{ paddingBottom: "40px", }}>
                    <p style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterSectionTitle">Company</p>

                    <br />

                    {this.props.showBlog == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="https://addy.beehiiv.com" target="_blank">
                        Blog
                    </a>}

                    <br />


                    {this.props.showPricing == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/pricing?ref=pricing-footer" target="_blank">
                        Pricing
                    </a>}

                    <br />

                    {this.props.showContactUs == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/contactus" target="_blank">
                        Contact Us
                    </a>}

                    <br />

                    {this.props.showHelpCenter == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/help-center" target="_blank">
                        Help Center
                    </a>}

                    <br />
                    {/* {this.props.showBlog == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/tiktok" target="_blank">
                        Redeem Purchase
                    </a>} */}

                    
                </div>


                <div className="col-md-3" style={{ paddingBottom: "40px" }}>
                    <p style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterSectionTitle">Product</p>


                    <br />

                    {this.props.showPricing == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="https://addyai.notion.site/Addy-for-Sales-3ff5c5a4c9b54958b958d6c415cdb444" target="_blank">
                        Sales
                    </a>}

                    <br />

                    {this.props.showPricing == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="https://addyai.notion.site/Addy-for-Customer-Support-b7aebd385c9f4233aa111bedb1548fd3" target="_blank">
                        Customer Support
                    </a>}

                    <br />

                    {this.props.showPricing == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn" target="_blank">
                        Chrome Extension
                    </a>}


                    

                </div>


                <div className="col-md-3" style={{ paddingBottom: "40px" }}>
                    <p style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterSectionTitle">Legal</p>
                    <br />

                    {this.props.showPrivacyPolicy == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/privacypolicy" target="_blank">
                        Privacy Policy
                    </a>}
                    <br />
                    {this.props.showTerms == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/termsofservice" target="_blank">
                        Terms of Service
                    </a>}

                    <br />
                    <br />
                    {this.props.showCompare == false ? <></> :<p style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterSectionTitle">Compare</p>}


                    {/* <br /> */}

                    {this.props.showPricing == false ? <></> : <a style={{
                        color: this.state.textColor ? this.state.textColor : "#4E5E70",
                    }} className="FooterTextCelestial Footer-link" href="/compare/try-ellie-ellie-ai-alternative" target="_blank">
                        Ellie (Try Ellie) alternative
                    </a>}

                </div>

                <div className="HorizontalFlex" style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    width: "100%"
                }}>

                    <div style={{
                        borderTop: "2px solid rgba(255, 255, 255, 0.15)",
                        width: "100%"
                    }}>

                    </div>

                </div>


                <div className="HorizontalFlex space-between" style={{
                    paddingBottom: "30px",
                    paddingTop: "30px"
                }}>
                    <div>
                        <p style={{
                            color: this.state.textColor ? this.state.textColor : "#4E5E70",
                        }} className="FooterTextCelestial">© 2024 Addy AI, Inc.</p>
                    </div>

                    <div className="HorizontalFlex">
                        {this.props.showTwitter == false ? <></> : <a className="SocialsLink" href="https://twitter.com/addyai_" target="_blank">
                            <FaTwitter color={this.state.iconColor ? this.state.iconColor : "0B3558"} size={styles.basic.smallScreen ? "1.4em" : "1.5em"} style={{ paddingRight: 5 }} />
                        </a>}

                        {this.props.showLinkedIn == false ? <></> : <a className="SocialsLink" href="https://www.linkedin.com/company/addy-ai/" target="_blank">
                            <FaLinkedin color={this.state.iconColor ? this.state.iconColor : "0B3558"} size={styles.basic.smallScreen ? "1.4em" : "1.5em"} style={{ paddingRight: 5 }} />
                        </a>}
                        {this.props.showInstagram == false ? <></> : <a className="SocialsLink" href="https://www.instagram.com/addy_hq" target="_blank">
                            <FaInstagram color={this.state.iconColor ? this.state.iconColor : "0B3558"} size={styles.basic.smallScreen ? "1.4em" : "1.5em"} style={{ paddingRight: 5 }} />
                        </a>}
                        {this.props.showInstagram == false ? <></> : <a className="SocialsLink" href="https://www.tiktok.com/@addy_ai_" target="_blank">
                            <FaTiktok color={this.state.iconColor ? this.state.iconColor : "0B3558"} size={styles.basic.smallScreen ? "1.4em" : "1.5em"} style={{ paddingRight: 5 }} />
                        </a>}
                        {this.props.showYoutube == false ? <></> : <a className="SocialsLink" href="https://www.youtube.com/@addyai" target="_blank">
                            <FaYoutube color={this.state.iconColor ? this.state.iconColor : "0B3558"} size={styles.basic.smallScreen ? "1.4em" : "1.5em"} style={{ paddingRight: 5 }} />
                        </a>}
                    </div>

                </div>
            </div>
        );
    }

    render() {
        return <div>{this.MainPage()}</div>;
    }
}

export default Footer;
