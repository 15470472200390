// App.js - WEB
import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { styles, updateDimensions } from "../styles";
import Header from ".././header";
import Footer from "../footer";
import HeaderDark from "../headerDark";

class AcceptBusinessInvite extends Component {
  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const organization = urlParams.get("org");
    const memberEmail = urlParams.get("email");
    const acceptCode = urlParams.get("code");

    this.state = {
      organization: organization,
      memberEmail: memberEmail,
      acceptCode: acceptCode,
      acceptanceState: "loading",
    };
  }

  updateDimensions() {
    updateDimensions();
    this.setState({});
  }

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions.bind(this));
    // Run Accepet
    this.fetchAccepts();
  }

  fetchAccepts = () => {
    const API_URL = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/organization";
    fetch(`${API_URL}/organization/accept-add-member-invite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // add the data to the request body
        organizationId: this.state.organization,
        acceptCode: this.state.acceptCode,
        memberEmail: this.state.memberEmail,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // Add the success responses
          this.setState({
            acceptanceState: "successful",
          });
          return;
        }
        // Not successful
        this.setState({
          acceptanceState: "failed",
        });
      })
      .catch((error) => {
        this.setState({
          acceptanceState: "failed",
        });
      });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillMount() {
    updateDimensions();
    this.setState({});
  }
  MainPage() {
    return (
      <div className="AboutUsContainer" style={{ height: "100vh" }}>
        <HeaderDark />

        {/* How */}
        <div
          className="AboutContainer"
          style={{
            width: "100%",
            paddingLeft: styles.blueBgPaddingLeft,
            justifyContent: "center",
            marginBottom: 50,
            paddingTop: 80,
          }}>
          <br />
          <br />
          <div className="HorizontalFlex CenterOppositeDirectionToo CenterContents">
            <h2 style={{ color: "#2B1C50" }}>Invite Accepted Successfully!! &nbsp; &#127881;</h2>
          </div>

          <p className="pricing-sub-helper">
            You are now part of your inviter's business plan. Please follow the following next steps to start using Addy
            AI.
          </p>
          <Row style={{ width: "100%", paddingTop: 30 }}>
            <Col md="12">
              <div
                style={{
                  backgroundColor: "#E9E9E9",
                  borderRadius: "10px",
                  padding: "15px",
                }}>
                <h4 style={{ color: "#2B1C50", marginBottom: "15px" }}>Next Steps &#128071;</h4>

                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "35px", margin: "0px", marginRight: "12px" }}>&#10122;</p>
                  <p style={{ fontSize: "22px", margin: "0px" }}>
                    Download the{" "}
                    <a
                      href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn"
                      target="_blank">
                      Addy AI Chrome extension
                    </a>
                    .
                  </p>
                </div>

                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "35px", margin: "0px", marginRight: "12px" }}>&#10123;</p>
                  <p style={{ fontSize: "22px", margin: "0px" }}>
                    Create an account with your invite email: {this.state.memberEmail}.
                  </p>
                </div>

                {/* <div className="HorizontalFlex CenterOppositeDirectionToo" style={{marginLeft: "40px"}}>
                            <p style={{fontSize: "15px", margin: "0px", marginRight: "12px"}}>&#9900;</p>
                            <p style={{fontSize: "15px", margin: "0px"}}>Click Compose a new email</p>
                        </div>
                        <div className="HorizontalFlex CenterOppositeDirectionToo" style={{marginLeft: "40px"}}>
                            <p style={{fontSize: "15px", margin: "0px", marginRight: "12px"}}>&#9900;</p>
                            <p style={{fontSize: "15px", margin: "0px"}}>Click Reply to any email in your inbox</p>
                        </div> */}

                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "35px", margin: "0px", marginRight: "12px" }}>&#10124;</p>
                  <p style={{ fontSize: "22px", margin: "0px" }}>
                    (Optional) Watch our{" "}
                    <a href="https://youtu.be/8Xr-RGOPaWA" target="_blank">
                      30 seconds tutorial
                    </a>{" "}
                    to see how to use it.
                  </p>
                </div>
              </div>

              <br />
              <br />

              <div
                style={{
                  backgroundColor: "#E9E9E9",
                  borderRadius: "10px",
                  padding: "15px",
                }}>
                <h4 style={{ color: "#2B1C50", marginBottom: "15px" }}>Please &#128591;</h4>

                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "22px", margin: "2px", marginRight: "12px" }}>&#128154;</p>
                  <p style={{ fontSize: "22px", margin: "2px" }}>
                    Support us by rating &#9733; &#9733; &#9733; &#9733; &#9733; on{" "}
                    <a
                      href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn"
                      target="_blank">
                      Chrome Web Store.
                    </a>
                  </p>
                </div>

                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "22px", margin: "2px", marginRight: "12px" }}>&#128036;</p>
                  <p style={{ fontSize: "22px", margin: "2px" }}>
                    Follow us on{" "}
                    <a href="https://twitter.com/AddyAI_" target="_blank">
                      Twitter
                    </a>{" "}
                    to stay updated.
                  </p>
                </div>
                <div className="HorizontalFlex CenterOppositeDirectionToo">
                  <p style={{ fontSize: "22px", margin: "2px", marginRight: "12px" }}>&#128075;</p>
                  <p style={{ fontSize: "22px", margin: "2px" }}>
                    Join our{" "}
                    <a href="https://discord.gg/h8uCZ9CNMG" target="_blank">
                      Discord
                    </a>{" "}
                    to provide feedback and suggest features.
                  </p>
                </div>
              </div>

              <br />
              <br />
            </Col>

            <Col md="12">
              <div
                style={{
                  backgroundColor: "#E9E9E9",
                  borderRadius: "10px",
                  padding: "15px",
                }}>
                <h4 style={{ color: "#2B1C50", marginBottom: "15px" }}>Features</h4>

                <div>
                  <div className="VerticalFlex">
                    <p style={{ fontSize: "18px", margin: "0px" }}>
                      1️⃣ Compose and reply to emails using AI with over 10 different tones.
                    </p>
                    <p style={{ fontSize: "18px", margin: "0px" }}>
                      Don't forget to click the expand button to see list of tones.
                    </p>
                    <br />
                    <img
                      src="https://i.imgur.com/bksQSe7.gif"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <br />

                  <div className="VerticalFlex">
                    <p style={{ fontSize: "18px", margin: "0px" }}>
                      2️⃣ Highlight any part of an email to rewrite it with AI.
                    </p>
                    <p style={{ fontSize: "18px", margin: "0px" }}>
                      You can choose "Improve writing", "Fix spelling", "Make shorter", etc.
                    </p>
                    <br />
                    <img
                      src="https://i.imgur.com/v1ED0Tr.gif"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <br />
                  <div className="VerticalFlex">
                    <p style={{ fontSize: "18px", margin: "0px" }}>3️⃣ Change response length and other settings.</p>
                    <p style={{ fontSize: "18px", margin: "0px" }}>
                      Control how long you want the AI-generated emails to be
                    </p>
                    <br />
                    <img
                      src="https://i.imgur.com/qiaFqTR.gif"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />

      </div>
    );
  }

  render() {
    console.log(this.state.acceptanceState);
    return (
      <div style={{ height: "100vh" }}>
        {this.state.acceptanceState == "loading" && (
          <div
            id="loading-overlay"
            style={{
              display: "flex",
              flexDirection: "column",
            }}>
            <div className="loader"></div>
            <br />
            <p>Accepting Invite...</p>
          </div>
        )}
        {this.state.acceptanceState == "successful" && this.MainPage()}
        {this.state.acceptanceState == "failed" && (
          <div
            style={{
              display: "flex",
              border: "1px solid black",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <p
              style={{
                color: "red",
                fontSize: "20px",
              }}>
              Failed: Invite may be invalid :(
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default AcceptBusinessInvite;
