// App.js - WEB
import React, { Component } from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity, Text, ImageBackground, Image, Modal } from "react-native-web";
import { InView } from 'react-intersection-observer';
import { FaTwitter, FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Global from "./Global";
import "./App.css";
import { updateDimensions, styles } from "./styles";
import { unregister } from "./registerServiceWorker";
import Header from "./header";
import Youtube from "react-youtube";
import Footer from "./footer";

import { ref, set, update, get } from "firebase/database";
import { database } from "./firebase.js";


import demoImage from "./img/email_main_demo.gif";
import VideoPlayer from "./components/video";
import Customers from "./components/customers";
import MultiLingualDemo from "./components/multilingualDemo";
import HeaderDark from "./headerDark.js";
import Faq from "./components/faq/faq.js";

var composeDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Fcompose_demo_simple.mp4?alt=media&token=ea96c05b-9a8e-4bd2-9397-4536f93b431c";
var followUpDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ffollow_up_demo_simple.mp4?alt=media&token=cb5ca2e6-2558-4a8f-8b8f-63a80a6e2d2e";
var trainingDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Ftraining_demo_simple.mp4?alt=media&token=2d5eea3c-4e5b-4577-bb68-d19b6a93f329";
var replyDemo = "https://firebasestorage.googleapis.com/v0/b/hey-addy-chatgpt.appspot.com/o/public%2FlandingPage%2Freply_demo_simple.mp4?alt=media&token=be11ab20-39ae-497e-9d32-f65fc450b71b";



var numEmailSent = 0;

var mainImageAlign = "center";
var confirmNameText = 48;

const visitId = uuidv4();

console.log("visitId", visitId);


const videoOptions = {
    playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        playlist: "3w_uWqq1xqk"
    },
};

class EmailAssistant extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        window.location.href = "https://addy.so";
        
        this.state = {
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,

            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        this.playerRef = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);

        // Log this visit
        this.logVisit(formattedVisitSource);
    }

    handleVideo = (inView) => {
        if (inView) {
            if (this.playerRef && this.playerRef.current) {
                this.playerRef.current.internalPlayer.playVideo();
            }
        } else {
            if (this.playerRef && this.playerRef.current) {
                this.playerRef.current.internalPlayer.pauseVideo();
            }
        }
    }


    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();
        unregister();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();

        document.body.addEventListener("scroll", () => {
            const headerElement = document.getElementById("addy-main-header");
            const scrollPosition = document.body.scrollTop;

            if (scrollPosition > 500) {
                if (headerElement) {
                    headerElement.style.boxShadow = "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            } else {
                if (headerElement) {
                    headerElement.style.boxShadow = "none";
                    // headerElement.style.backgroundColor = "#FFFFFF";
                }

            }
        });
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source);
        window.open(
            "https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    logVisit(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var visitRef = ref(database, `Analytics/SiteVisit/${source}/${visitId}`);
        set(visitRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/VisitCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    logClickChrome(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var clickRef = ref(database, `Analytics/DownloadClick/${source}/${visitId}`);
        set(clickRef, {
            date: date,
            id: visitId,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/DownloadClickCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };
    sendConfirmation = (email, endpoint) => {
        // hit endpoint with email
        // If it failed try once again.
        numEmailSent += 1;
        const url = new URL("https://api.ratemynft.app/api/sendlandingconfirmationemail");
        const data = {
            email: email,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify(response));
                if (!response.metadata || response.metadata.result !== "success") {
                    // Something went wrong TODO: Log this.
                    if (numEmailSent < 3) {
                        // this.sendConfirmation(email);
                    }
                } else {
                    // Success response.
                }
            })
            .catch((error) => {
                // TODO: Log this error
                if (numEmailSent < 3) {
                    this.sendConfirmation(email);
                }
            });
    };
    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };
    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer" style={{
                    backgroundColor: "rgb(32, 33, 36)"
                }}>
                    <HeaderDark />

                    

                    <div className="PrimarySectionContainer" style={{
                        backgroundColor: "rgb(32, 33, 36)",
                        paddingBottom: "70px",
                        // minHeight: "100vh"
                    }}>


                        {/* First background */}
                        <View style={{ paddingTop: 40, paddingLeft: styles.basic.blueBgPaddingLeft, alignContent: "center", justifyContent: "center" }}>

                            {/* <div style={{
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <div className="site-announcement" onClick={() => window.open("https://discord.gg/h8uCZ9CNMG", "_blank")} style={{
                                    fontSize: styles.basic.smallScreen ? "12px" : "14px"
                                }}>
                                    &#127881; We're building something new in Stealth mode. <a style={{
                                        textDecoration: "underline",
                                        color: "rgba(255, 255, 255, 0.8)"
                                    }} href="https://discord.gg/h8uCZ9CNMG">Stay updated</a>
                                </div>

                            </div> */}

                            <Row style={{ width: "100%", paddingBottom: 0 }}>
                                {/* The first text lives here */}
                                <Col lg="6">
                                    {/* Text View #6D53AF */}
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 40,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingBottom: 25,
                                        }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex", flexDirection: "column",
                                            justifyContent: "center",
                                        }}>

                                            <div style={{
                                                width: "97%", display: "flex",
                                                flexDirection: "column",
                                                // alignItems: "center",
                                                justifyContent: "center"
                                            }}>


                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: styles.basic.mainText,
                                                        fontWeight: "800",
                                                        color: "#FFFFFF",
                                                        textAlign: "left",
                                                        fontFamily: "SF-Pro-Display-Bold",
                                                    }}>
                                                    Write emails 10x faster with AI
                                                    {/* <mark
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            color: "#745DDE",
                                                        }}>10x faster</mark> */}
                                                    {/* with AI */}
                                                </Text>
                                            </div>
                                            {/* <Text className="OverflowBreak" style={{
                                                fontSize: styles.basic.mainText, fontWeight: "800", color: '#000000',
                                                fontFamily: 'SF-Pro-Display-Bold'
                                            }}>&nbsp;not days</Text> */}


                                            <div className="subTextDiv">
                                                {/* <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>
                                                    Save time spent responding to customer emails by training AI
                                                </Text>
                                                <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>&nbsp;to do it for your business 24-7⚡️</Text> */}

                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: (styles.basic.subText).toString() + "px",
                                                        fontWeight: "bold",
                                                        color: "#e9eaee",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                    }}>
                                                    Save hours a day on emails. Train Addy to compose,
                                                    reply and follow up to your emails 24/7 ⚡️

                                                    {/* Let AI handle your emails so you can focus on real work ⚡️ */}
                                                </Text>
                                                {/* <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: (styles.basic.subText).toString() + "px",
                                                        fontWeight: "bold",
                                                        color: "#151516",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                    }}>
                                                    &nbsp;customer inquiries 24-7⚡️
                                                </Text> */}
                                            </div>

                                            <div className="ChecksDiv" style={{
                                                display: "flex",
                                                gap: 10,
                                                flexWrap: "wrap"
                                            }}>
                                                <p className="check-text" style={{
                                                    color: "#e9eaee"
                                                }}>
                                                    <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark>Trained to sound like you
                                                </p>
                                                <p className="check-text" style={{
                                                    color: "#e9eaee"
                                                }}>
                                                    <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark> 100+ languages
                                                </p>
                                                <p className="check-text" style={{
                                                    color: "#e9eaee"
                                                }}>
                                                    <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark> Works everywhere
                                                </p>
                                            </div>
                                        </div>
                                    </View>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "40px"
                                        // justifyContent: "center",
                                    }}>
                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            padding: "20px",
                                            borderRadius: "40px",
                                            gap: "15px"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                                width="30" />
                                            <p className="SubmitText" style={{
                                                backgroundColor: "#8ab4f8", color: "black",
                                                paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                                paddingRight: "0px"
                                            }}>Download Chrome Extension</p>
                                        </TouchableOpacity>
                                        {/*
                    <TouchableOpacity onPress={() => this.joinButtonPressed()}>
                      <p className="SubmitText">Log in</p>
                    </TouchableOpacity> 
                        */}


                                    </div>

                                    <div className="HorizontalFlex CenterOppositeDirectionToo" style={{
                                        marginTop: "30px"
                                    }}>
                                        <img alt="sample" width="160" height="40" src="https://i.imgur.com/xW3XP3n.png" />
                                        <p class="footer-helper" style={{
                                            marginLeft: "10px",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            width: "fit-content",
                                            margin: "0px"
                                        }}>Trusted by over 10,000 people</p>
                                    </div>
                                </Col>
                                {/* Download button lives here but will be hidden on mobile */}
                                <Col lg="6">
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 40,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // padding: "45px",
                                            borderRadius: "30px",
                                            minWidth: "100%",

                                        }}>
                                        <div
                                            className="VerticalFlex"
                                            style={{ alignItems: mainImageAlign, }}>
                                            <img className="MainImagePromoElevated"
                                                src={demoImage}
                                                style={{
                                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                                    width: (styles.basic.midImageWidth).toString() + "px",
                                                    height: (styles.basic.midImageWidth).toString() + "px",
                                                    borderRadius: "20px",
                                                    objectFit: "cover",
                                                }} />
                                        </div>


                                    </View>
                                    {/* <div className="ChecksDiv" style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 8,
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "center",
                                    }}>
                                        <p className="check-text" style={{
                                            color: "#e9eaee"
                                        }}>
                                            <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark> Compose
                                        </p>
                                        <p className="check-text" style={{
                                            color: "#e9eaee"
                                        }}>
                                            <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark> Reply
                                        </p>
                                        <p className="check-text" style={{
                                            color: "#e9eaee"
                                        }}>
                                            <mark className="check-mark"><FaCheckCircle color="#8ab4f8" /></mark> Follow up
                                        </p>
                                    </div> */}
                                </Col>
                            </Row>
                        </View>
                    </div>

                    <div className="AboutContainer" style={{
                        // backgroundColor: "#3c4043",
                        backgroundColor: "#28292a",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                    }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 12,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "#FFFFFF",
                            marginBottom: styles.basic.smallScreen ? "20px" : "50px"
                        }}>
                            Trusted by professionals at</p>

                        <div>
                            <Customers />
                        </div>

                    </div>


                    <div className="yt-video-parent" style={{
                        backgroundColor: "#3c4043",
                        paddingTop: "40px",
                        paddingBottom: "40px",
                    }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 7,
                            paddingBottom: "0px"
                        }}><mark
                            style={{
                                backgroundColor: "transparent",
                                // color: "#745DDE",
                                color: "#F1F0F3",

                            }}>Demo</mark></p>

                        <InView as="div" onChange={this.handleVideo}>
                            <div className="video-wrapper">
                                <Youtube videoId="3w_uWqq1xqk" opts={videoOptions} ref={this.playerRef} />
                            </div>
                        </InView>

                        <div className="HorizontalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "40px"
                        }}>
                            <TouchableOpacity style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                padding: "20px",
                                borderRadius: "40px",
                                gap: "15px",
                            }} onPress={() => this.sendButtonPressed()}>
                                <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                    width="30" />
                                <p className="SubmitText" style={{

                                    backgroundColor: "#8ab4f8", color: "black",
                                    paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                    paddingRight: "0px"
                                }}>Download Chrome Extension</p>
                            </TouchableOpacity>
                        </div>

                    </div>

                    

                    {/* <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "100px",
                        paddingBottom: "100px",
                        backgroundColor: "rgb(32, 33, 36)",
                    }}>
                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 7,
                            paddingBottom: "10px"
                        }}><mark
                            style={{
                                backgroundColor: "transparent",
                                color: "#F1F0F3",

                            }}>Addy can write emails in 100+ languages</mark></p>
                        <p style={{
                            ...styles.headerSubtitle,
                            paddingBottom: "50px"
                        }}><mark style={{
                            backgroundColor: "transparent",
                            color: "rgba(255, 255, 255, 0.8)",
                        }}>
                                So you can say goodbye to language barriers. Try it yourself below!
                            </mark></p>


                        <MultiLingualDemo />
                    </div> */}

                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                // border: "1px solid yellow"
                                // backgroundColor: "rgba(211, 211, 211, 0.15)",
                            }
                        }>
                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingTop: styles.basic.smallScreen ? 50 : 100,
                                paddingBottom: styles.basic.smallScreen ? 60 : 180,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",


                            }}>
                            <p style={{
                                ...styles.headerTextDark,
                                fontSize: styles.basic.titleText,
                                fontFamily: "SF-Pro-Display-Regular",
                                color: "#FFFFFF",
                                marginBottom: styles.basic.smallScreen ? "10px" : "40px"
                            }}>
                                How it works</p>

                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: styles.basic.smallScreen ? 30 : 90, paddingBottom: styles.basic.smallScreen ? 10 : 60, width: "96%" }}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#FFFFFF"

                                        }}>COMPOSE</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#FFFFFF",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>
                                            Get polished drafts in seconds
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Simply tell Addy your email topic,
                                            and watch the magic happen! Addy will write a well polished
                                            email for you —right within your email client.
                                        </Text>

                                        <br />
                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Get started for free
                                        </TouchableOpacity>

                                        <br />
                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: styles.basic.smallScreen ? 10 : 60,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                        }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            // backgroundColor: "#D2E2FB",
                                            backgroundColor: "transparent",
                                        }}>
                                            <VideoPlayer source={composeDemo}
                                                width={styles.basic.midImageWidth}
                                                height={styles.basic.midImageWidth}
                                                thumbnail="https://i.imgur.com/Pncr1Ph.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                paddingBottom: styles.basic.smallScreen ? 24 : 120,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Row style={{ width: "100%" }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                                    <div
                                        className="FullParentDimensions VerticalFlex OSImageContainer"
                                        style={{ alignItems: mainImageAlign }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            // backgroundColor: "#D2E2FB",
                                            backgroundColor: "transparent",

                                        }}>
                                            <VideoPlayer source={replyDemo}
                                                width={styles.basic.midImageWidth}
                                                height={styles.basic.midImageWidth}
                                                thumbnail="https://i.imgur.com/mIAuCU0.png" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                                    <View style={styles.airportIdentifierImageView}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#FFFFFF"

                                        }}>REPLY</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#FFFFFF",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Get context-specific replies instantly</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Addy adapts to your unique writing style and
                                            the context of your email thread
                                            to craft smart, personalized replies.


                                        </Text>
                                        <br />
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>It even analyzes the intent behind incoming emails
                                            to offer you tailored reply options.</Text>
                                        <br />

                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Get started for free
                                        </TouchableOpacity>
                                        {/* <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>Just click on a suggestion, and you'll have a complete,
                                            well-crafted email response in seconds</Text> */}
                                        <br />
                                    </View>
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="container"
                            style={{
                                width: "100%",
                                // paddingLeft: styles.basic.blueBgPaddingLeft,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingBottom: styles.basic.smallScreen ? 50 : 100,
                            }}>
                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: styles.basic.smallScreen ? 30 : 40, paddingBottom: styles.basic.smallScreen ? 5 : 60, width: "96%" }}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#FFFFFF"

                                        }}>FOLLOW UP</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#FFFFFF",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Write follow up emails in one click</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>
                                            Never let an important conversation slip through
                                            the cracks again. With Addy's one click follow-up
                                            feature, you can write a follow-up email that's
                                            right on time and fits the conversation —all
                                            with just one click.
                                        </Text>

                                        <br />

                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Get started for free
                                        </TouchableOpacity>

                                        <br />

                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: styles.basic.smallScreen ? 5 : 40,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        <div className="BorderRadiusImage" style={{
                                            // backgroundColor: "#D2E2FB",
                                            backgroundColor: "transparent",
                                            minWidth: styles.basic.midImageWidth,
                                        }}>
                                            <VideoPlayer source={followUpDemo}
                                                width={styles.basic.midImageWidth}
                                                height={styles.basic.midImageWidth}
                                                thumbnail="https://i.imgur.com/1mZmkgn.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        
                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                paddingBottom: styles.basic.smallScreen ? 60 : 120,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Row style={{ width: "100%" }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                                    <div
                                        className="FullParentDimensions VerticalFlex OSImageContainer"
                                        style={{ alignItems: mainImageAlign }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            // backgroundColor: "#D2E2FB",
                                            backgroundColor: "transparent",

                                        }}>
                                            <img width={styles.basic.midImageWidth} height={styles.basic.midImageWidth}
                                                src="https://i.imgur.com/xAuu39A.jpg" style={{
                                                    borderRadius: "20px"
                                                }}/>
                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                                    <View style={styles.airportIdentifierImageView}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#FFFFFF"

                                        }}>AUTO SUMMARY</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#FFFFFF",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Automatically summarize long emails</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>

                                            Addy provides instant summaries
                                            of long emails and conversations. You can quickly get important details
                                            like action items and deadlines, without wasting minutes of reading long emails.
                                            
                                            These summaries update in real-time as
                                            conversations progress.


                                        </Text>
                                        
                                        <br />

                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "#8ab4f8",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "#000000"
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Get started for free
                                        </TouchableOpacity>
                                        {/* <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>Just click on a suggestion, and you'll have a complete,
                                            well-crafted email response in seconds</Text> */}
                                        <br />
                                    </View>
                                </Col>
                            </Row>
                        </div>




                    </div>

                    <div
                        className="container"
                        style={{
                            width: "100%",
                            // paddingLeft: styles.basic.blueBgPaddingLeft,
                            justifyContent: "center",
                            paddingBottom: 20,
                            paddingTop: "70px",
                            backgroundColor: "rgba(211, 211, 211, 0.15)",
                            // backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backgroundColor: "#3c4043",
                            // backgroundColor: "#110F19",
                            backgroundColor: "#34363a",
                            borderRadius: "25px",
                            border: "2px solid #8AB4F8",
                            // border: "2px solid #D2E2FC",
                        }}>

                        <div className="row" style={{
                            paddingBottom: "60px"
                        }}>

                            <div className="col-md-5">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "25px",
                                    paddingBottom: "40px"
                                    // justifyContent: "center"
                                }}>

                                    <Text style={{
                                        ...styles.headerTextNew,
                                        fontFamily: "SF-Pro-Display-Light",
                                        fontSize: "15px",
                                        color: "#8AB4F7",
                                        color: "#FFFFFF"

                                    }}>TRAIN</Text>
                                    <Text style={{
                                        ...styles.sectionSubtitle,
                                        color: "#FFFFFF",
                                        fontFamily: "SF-Pro-Display-Regular",
                                    }}>Train Addy to sound like you</Text>
                                    <Text
                                        style={{
                                            fontSize: styles.basic.normalText,
                                            lineHeight: styles.basic.normalText * 1.7,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>
                                        Addy can learn from your unique knowledge base to craft personalized emails that are tailored to
                                        your unique voice and expertise.
                                    </Text>

                                    <br />

                                    <Text
                                        style={{
                                            fontSize: styles.basic.normalText,
                                            lineHeight: styles.basic.normalText * 1.7,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontSize: styles.basic.normalText - 2,
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>
                                        1. &nbsp;Add PDFs, documents, or website links to your Addy dashboard.
                                    </Text>

                                    <Text
                                        style={{
                                            fontSize: styles.basic.normalText,
                                            lineHeight: styles.basic.normalText * 1.7,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontSize: styles.basic.normalText - 2,
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>
                                        2. &nbsp;Addy uses your knowledge base to craft context-rich emails.
                                    </Text>

                                    <Text
                                        style={{
                                            fontSize: styles.basic.normalText,
                                            lineHeight: styles.basic.normalText * 1.7,
                                            fontWeight: "normal",
                                            color: "rgba(255, 255, 255, 0.8)",
                                            textAlign: "left",
                                            fontSize: styles.basic.normalText - 2,
                                            fontFamily: "SF-Pro-Display-Light",
                                        }}>
                                        3. &nbsp;Your data is safe and only used to enhance your experience.
                                    </Text>



                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="answer" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <VideoPlayer source={trainingDemo}
                                        thumbnail="https://i.imgur.com/SAJAcup.png"
                                        actualVideoWidth={styles.basic.demoImageWidth - 50}
                                        actualVideoHeight={styles.basic.demoImageWidth - 50}
                                        width={styles.basic.demoImageWidth - 50}
                                        height={styles.basic.demoImageWidth - 50} />
                                </div>
                            </div>

                        </div>

                    </div>




                    <div
                        className="AboutContainer container"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            paddingTop: 60,
                            marginTop: styles.basic.smallScreen ? 60 : 150,
                            paddingBottom: 20,
                            backgroundColor: "#202125",
                            // backgroundColor: "#F9FAFF",
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "#FFFFFF",
                            marginBottom: "15px"
                        }}>
                            What our customers say</p>
                        <p style={{
                            ...styles.headerSubtitle,
                            color: "rgba(255, 255, 255, 0.8)"
                        }}>Addy has helped write over <mark style={{
                            backgroundColor: "transparent",
                            fontWeight: "bold",
                            color: "rgba(255, 255, 255, 0.8)"
                        }}>1 million</mark> emails for 11,972 happy people</p>
                        <br />
                        <div className="row" style={{ marginTop: "30px", padding: 0 }}>



                            <div className="col-lg-4 justify-content-center">

                                <div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-Nci3gsVHvQUn9GHJzBq" src="https://embed-v2.testimonial.to/share/-Nci3gsVHvQUn9GHJzBq" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-Nci3qKs832jDfZReW3_" src="https://embed-v2.testimonial.to/share/-Nci3qKs832jDfZReW3_" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>

                            </div>


                            <div className="col-lg-4 justify-content-center">

                                <div>
                                    <div style={{ height: "350px" }}>
                                        <iframe id="-Nci485hCQyeelwznqxu" src="https://embed-v2.testimonial.to/share/-Nci485hCQyeelwznqxu" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    <div style={{ height: "430px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>




                            </div>

                            <div className="col-lg-4 justify-content-center">



                                <div>
                                    <div style={{ height: "300px", }}>
                                        <iframe id="-N_jglbWrXdObHILrny4" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-Nci61gJFakb1B4GSr7r" src="https://embed-v2.testimonial.to/share/-Nci61gJFakb1B4GSr7r" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="AboutContainer"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            marginTop: 40,
                            paddingBottom: 80,
                            paddingTop: 50,
                            // backgroundColor: "#202125",
                            // backgroundColor: "#F9FAFF",

                            backgroundColor: "#34363a",
                            // backgroundColor: "#28292a"
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 12,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "#FFFFFF",
                            marginBottom: "15px"
                        }}>
                            Get to zero inbox today</p>

                        <div className="HorizontalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "40px"
                        }}>


                            <TouchableOpacity style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#8ab4f8",
                                padding: "20px",
                                borderRadius: "40px",
                                gap: "15px",
                            }} onPress={() => this.sendButtonPressed()}>
                                <img src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                                    width="30" />
                                <p className="SubmitText" style={{

                                    backgroundColor: "#8ab4f8", color: "black",
                                    paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", fontSize: "22px",
                                    paddingRight: "0px"
                                }}>Download Chrome Extension</p>
                            </TouchableOpacity>
                        </div>

                    </div>

                    <div className="AboutContainer container">
                        <Faq/>
                    </div>


                    <div className="AboutContainer container-fluid"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            paddingBottom: 80,
                            backgroundColor: "#202125",
                            backgroundColor: "rgba(255, 255, 255, 01)",
                            // backgroundColor: "#3c4043",

                            paddingTop: "70px"
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 9,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "rgba(0, 0, 0, 0.8)",
                            fontWeight: "normal",
                            marginBottom: "15px"
                        }}>
                            Proudly backed by</p>

                        <div className="VerticalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "20px",
                        }}>

                            <div style={{
                                display: "flex",
                                gap: 30,
                                flexWrap: "wrap"
                            }}>
                                <img src="https://i.imgur.com/v33lYtV.png"
                                    width={ styles.basic.smallScreen ? "300px" : "360px"} height="auto" />

                                <img src="https://i.imgur.com/AR2u6gb.png"
                                    width={ styles.basic.smallScreen ? "300px" : "360px"} height="auto" />
                            </div>



                            <p style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "21px",
                                width: "100%",
                                textAlign: styles.basic.smallScreen ? "left" : "center"

                            }}>
                                And notable Angels - Merline Saintil
                            </p>

                        </div>

                    </div>





                    <Footer backgroundColor="rgb(32, 33, 36)" textColor="#FFFFFF" iconColor="#FFFFFF" />
                </div>
            );
        } else {
            return null;
        }
    }

    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <Modal style={styles.mainConfirmationView}>
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                        }}>
                        <Text
                            style={{
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 90,
                                marginTop: 50,
                            }}>
                            The Future of Email is Here
                        </Text>
                        <Image style={styles.checkImage} source={require("./img/check_circle.svg")} resizeMode="cover" />
                        <Text
                            style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 18,
                                marginTop: 30,
                            }}>
                            Thank you!!
                        </Text>
                        <Text style={styles.youSigned}>Your email was successfully submitted.</Text>
                        <Text style={styles.wewill}>We will add you to the waitlist</Text>
                        <View style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></View>
                        {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText">Addy Email Assistant</p>
                        </div>
                        <br />
                        <br />
                    </View>

                    {/* <Image
                      style={styles.confirmationImage}
                      source={this.state.imageSrc || require('./img/frame2.png')}
                      resizeMode='cover'
                    /> */}
                </Modal>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                {this.Showconfirmation()}
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <EmailAssistant />;
}
