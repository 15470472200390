// App.js - WEB
import React, { Component } from "react";
import { View, StyleSheet, Dimensions, TouchableOpacity, Text, ImageBackground, Image, Modal } from "react-native-web";
import { FaTwitter, FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Global from "./Global";
import "./App.css";
import { updateDimensions, styles } from "./styles";
import { unregister } from "./registerServiceWorker";
import Header from "./header";
import Youtube from "react-youtube";
import Footer from "./footer";
import CustomerReel from "./CustomerReel";
import { InlineWidget } from "react-calendly";

import { ref, set, update, get } from "firebase/database";
import { database } from "./firebase.js";
import Demo from "./demos";
import FeatureCard from "./card";

import multiChannelImage from "./img/multichannel_2.png";
import trainingImage from "./img/training_2.png";
import repliesForYouImage from "./img/replies_for_you.gif";
import demoImage from "./img/main_demo.gif"; 

var numEmailSent = 0;

var mainImageAlign = "center";
var midImageWidth = 400;
var mainText = 50;
var subText = 24;
var blueBgPaddingLeft = 60;
var headerHorizontalPadding = 60;
var confirmNameText = 48;

let email = "";
const visitId = uuidv4();
console.log("visitId", visitId);

class App extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        const refSource = urlParams.get("ref") == null ? "general" : urlParams.get("ref");

        let formattedVisitSource = visitSource == null ? refSource : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        const emailAddressSource = urlParams.get("email") == null ? null : urlParams.get("email");

        this.state = {
            isHuman: false,
            name: "",
            email: "",
            timestamp: "",
            browser: "",
            location: "",
            duration: "",
            showConfirmation: false,
            showMainView: true,
            source: formattedVisitSource,
            emailAddressSource: emailAddressSource,
            //   showConfirmation: true,
            //   showMainView: false,
        };
        this.nameInput = React.createRef();
        this.emailInput = React.createRef();
        // this.handleNameInput = this.handleNameInput.bind(this);
        // this.handleNameChange = this.handleNameChange.bind(this);

        // Log this visit
        this.logVisit(formattedVisitSource, emailAddressSource);
    }
    handleNameInput = (event) => {
        event.preventDefault();
        this.setState({
            name: event.target.value,
        });
    };
    handleEmailInput = (event) => {
        event.preventDefault();
        this.setState({
            email: event.target.value,
        });
    };
    updateDimensions() {
        updateDimensions();
    }
    getDeviceDetails() {
        // alert(startDate.toString());
    }
    /**
     * Add event listener
     */
    componentDidMount() {
        this.updateDimensions();
        // this.getDeviceDetails();
        unregister();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone visited
        // TODO: Consider adding this back
        // this.alertTeam();
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillMount() {
        updateDimensions();
    }
    callback = () => {
        // alert("Recaptcha loaded")
    };
    sendButtonPressed = () => {
        // Log this click
        // Previously -> "https://chrome.google.com/webstore/detail/addyai-ai-email-assistant/gldadickgmgciakdljkcpbdepehlilfn",
        this.logClickChrome(this.state.source, this.state.emailAddressSource);
        window.open(
            "https://calendly.com/michael-addy-ai/addy-ai-demo",
            "_blank"
        );
    };

    joinButtonPressed = () => {
        window.location.href = "./signup";
    };

    logVisit(source, emailAddressSource = null) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var visitRef = ref(database, `Analytics/SiteVisit/${source}/${visitId}`);
        set(visitRef, {
            date: date,
            id: visitId,
            emailAddressSource: emailAddressSource ?
                emailAddressSource : "null",
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/VisitCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    logClickChrome(source, emailAddressSource = null) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        var clickRef = ref(database, `Analytics/DownloadClick/${source}/${visitId}`);
        set(clickRef, {
            date: date,
            id: visitId,
            emailAddressSource: emailAddressSource ? emailAddressSource : "null",
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/DownloadClickCount/${source}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });
    }

    removeChars = (string) => {
        return string.replace(/[^a-zA-Z0-9_@-]/g, "");
    };
    sendConfirmation = (email, endpoint) => {
        // hit endpoint with email
        // If it failed try once again.
        numEmailSent += 1;
        const url = new URL("https://api.ratemynft.app/api/sendlandingconfirmationemail");
        const data = {
            email: email,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(JSON.stringify(response));
                if (!response.metadata || response.metadata.result !== "success") {
                    // Something went wrong TODO: Log this.
                    if (numEmailSent < 3) {
                        // this.sendConfirmation(email);
                    }
                } else {
                    // Success response.
                }
            })
            .catch((error) => {
                // TODO: Log this error
                if (numEmailSent < 3) {
                    this.sendConfirmation(email);
                }
            });
    };
    onAppLogoClick = () => {
        window.location.href = "/";
        // window.open("https://gifttutoring.org/attachments/download/4563/GIFTSym10_Proceedings_Final_052722.pdf", '_blank');
    };
    MainPage() {
        if (this.state.showMainView == true) {
            return (
                <div className="AboutUsContainer">
                    <Header />

                    <div className="PrimarySectionContainer" style={{
                        paddingBottom: (window.innerWidth < 500) ? "120px" : "150px",
                    }}>
                        {/* First background */}
                        <View style={{ paddingLeft: styles.basic.blueBgPaddingLeft, alignContent: "center", justifyContent: "center" }}>
                            <Row style={{ width: "100%", paddingTop: 40, paddingBottom: 35 }}>
                                {/* The first text lives here */}
                                <Col lg="6">
                                    {/* Text View #6D53AF */}
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 75,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingBottom: 25,
                                        }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex", flexDirection: "column",
                                            justifyContent: "center",
                                        }}>

                                            <div style={{
                                                width: "97%", display: "flex",
                                                flexDirection: "column",
                                                // alignItems: "center",
                                                justifyContent: "center"
                                            }}>


                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: styles.basic.mainText,
                                                        fontWeight: "800",
                                                        color: "#2B1C50",
                                                        textAlign: "left",
                                                        fontFamily: "SF-Pro-Display-Bold",
                                                    }}>
                                                    Resolve your customer inquiries 10x faster with AI
                                                    {/* <mark
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            color: "#745DDE",
                                                        }}>10x faster</mark> */}
                                                    {/* with AI */}
                                                </Text>
                                            </div>
                                            {/* <Text className="OverflowBreak" style={{
                                                fontSize: styles.basic.mainText, fontWeight: "800", color: '#000000',
                                                fontFamily: 'SF-Pro-Display-Bold'
                                            }}>&nbsp;not days</Text> */}


                                            <div className="subTextDiv">
                                                {/* <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>
                                                    Save time spent responding to customer emails by training AI
                                                </Text>
                                                <Text className="OverflowBreak" style={{
                                                    fontSize: ((subText * 1.2).toString() + "px"), fontWeight: "bold", color: "#151516",
                                                    fontFamily: 'SF-Pro-Rounded-Light'
                                                }}>&nbsp;to do it for your business 24-7⚡️</Text> */}

                                                <Text
                                                    className="OverflowBreak"
                                                    style={{
                                                        fontSize: (styles.basic.subText - 4).toString() + "px",
                                                        // fontWeight: "bold",
                                                        color: "#151516",
                                                        fontFamily: "SF-Pro-Rounded-Light",
                                                    }}>
                                                    Put your customer support on autopilot by training a custom AI to resolve over 60% of your customer issues 24/7 ⚡️
                                                </Text>

                                            </div>

                                            <div className="ChecksDiv" style={{
                                                display: "flex",
                                                gap: 10,
                                                flexWrap: "wrap"
                                            }}>
                                                <p className="check-text">
                                                    <mark className="check-mark"><FaCheckCircle /></mark> Multichannel
                                                </p>
                                                <p className="check-text">
                                                    <mark className="check-mark"><FaCheckCircle /></mark> Train with your data
                                                </p>
                                                <p className="check-text">
                                                    <mark className="check-mark"><FaCheckCircle /></mark> Set up in minutes
                                                </p>
                                            </div>
                                        </div>
                                    </View>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "center",
                                    }}>
                                        <TouchableOpacity onPress={() => this.sendButtonPressed()}>
                                            <p className="SubmitText">Request a Demo</p>
                                        </TouchableOpacity>

                                    </div>
                                </Col>
                                <Col lg="6">
                                    <View
                                        style={{
                                            height: "fit-content",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: (window.innerWidth < 500) ? 20 : 75,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: (window.innerWidth < 500) ? "20px" : "45px",
                                            borderRadius: "30px",
                                            backgroundColor: window.innerWidth < 500 ? "transparent" : "#9F92EC",
                                            minWidth: "100%",

                                        }}>
                                        <div
                                            className="VerticalFlex"
                                            style={{ alignItems: mainImageAlign, }}>
                                            <img className="MainImagePromoElevated"
                                                src={demoImage}
                                                style={{
                                                    // boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                                    boxShadow: "0 0.9375rem 3.125rem 0 #7e75e6",
                                                    width: (styles.basic.midImageWidth).toString() + "px",
                                                    height: (styles.basic.midImageWidth).toString() + "px",
                                                    borderRadius: "20px",
                                                    objectFit: "cover",
                                                }} />
                                        </div>
                                    </View>
                                </Col>
                            </Row>
                        </View>
                    </div>

                    <div
                        className="SecurityContainer"
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "row",
                            // paddingTop: 80,
                            backgroundColor: "white",
                            // paddingBottom: 10,

                        }}>
                        <View style={{
                            alignContent: "center", justifyContent: "center",
                            // border: "1px solid red",
                            borderRadius: (window.innerWidth < 500) ? "20px" : "30px",
                            overflow: "hidden",
                            width: "90%",
                            position: "relative",
                            bottom: "100px",
                            backgroundColor: "white",
                            paddingTop: "40px",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        }}>
                            <div
                                className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv"
                                style={{
                                    width: "100%",
                                    // marginBottom: 80,
                                    width: "100%",
                                }}>
                                {/* <Text style={{
                                    ...styles.headerTextWhite2,
                                    fontSize: styles.basic.titleText - 5
                                }}>
                                    Trusted by{" "}
                                    <mark
                                        style={{
                                            backgroundColor: "transparent",
                                            color: "#745DDE",
                                        }}>
                                        10,000+
                                    </mark>{" "}
                                    customers
                                </Text> */}

                                <p style={{
                                    ...styles.headerTextDark,
                                    fontSize: styles.basic.titleText * 0.75,
                                    fontFamily: "SF-Pro-Display-Regular",
                                    color: "#2B1C50",
                                    marginBottom: "10px"
                                }}>
                                    Trusted by leading brands</p>
                                <br />

                                <div className="HorizontalFlex">
                                    <CustomerReel
                                        customerList={[
                                            { name: "Getir_BrowserStack", logo: "https://i.imgur.com/Ud503yl.png" },
                                            { name: "WeightRx_Hub", logo: "https://i.imgur.com/ohdD9IS.png" },
                                            { name: "TalentLyft_Transcard", logo: "https://i.imgur.com/gr8AIeG.png" },
                                        ]}
                                    />

                                    <CustomerReel
                                        customerList={[
                                            { name: "Getir_BrowserStack", logo: "https://i.imgur.com/Ud503yl.png" },
                                            { name: "WeightRx_Hub", logo: "https://i.imgur.com/ohdD9IS.png" },
                                            { name: "TalentLyft_Transcard", logo: "https://i.imgur.com/gr8AIeG.png" },
                                        ]}
                                    />

                                </div>
                            </div>
                        </View>
                    </div>



                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                // border: "1px solid yellow"
                                // backgroundColor: "rgba(211, 211, 211, 0.15)",
                            }
                        }>
                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                justifyContent: "center",
                                paddingTop: 30,
                                paddingBottom: (window.innerWidth < 500) ? 30 : 100,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",


                            }}>
                            <p style={{
                                ...styles.headerTextDark,
                                fontSize: styles.basic.titleText,
                                fontFamily: "SF-Pro-Display-Regular",
                                color: "#2B1C50",
                                marginBottom: "50px"
                            }}>
                                Resolve complex inquiries in seconds</p>

                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: (window.innerWidth < 500) ? 0 : 45,
                                        paddingBottom: (window.innerWidth < 500) ? 0 : 60, width: "96%",
                                        paddingRight: (window.innerWidth < 500) ? 0 : 20 }}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#111019"

                                        }}>TRAIN</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#111019",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>
                                            Train your A.I. to be an expert on your business
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgb(108, 102, 132)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Regular",
                                            }}>
                                            Empower your AI to master your business in under 5 minutes.
                                            Easily link your e-commerce platform and essential apps.
                                            With Addy AI, your AI gets smarter by seamlessly integrating with the tools you already use.
                                        </Text>

                                        <br />
                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // backgroundColor: "#565ADC",
                                            color: "rgb(75, 66, 173)",
                                            backgroundColor: "#EFF0FF",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Request a demo
                                        </TouchableOpacity>

                                        <br />
                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: 30,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 30,
                                        }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            backgroundColor: "#EFF0FF",
                                            // backgroundColor: "transparent",
                                        }}>
                                            <Image
                                                style={[styles.image, { width: 0.9 * styles.basic.midImageWidth, height: 0.9 * styles.basic.midImageWidth }]}
                                                source={require("./img/integrations_blend.gif")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="container"
                            style={{
                                width: "100%",
                                paddingLeft: styles.basic.blueBgPaddingLeft,
                                paddingBottom: (window.innerWidth < 500) ? 20 : 120,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <Row style={{ width: "100%" }}>
                                <Col lg={{ span: 6, order: 1 }} md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                                    <div
                                        className="FullParentDimensions VerticalFlex OSImageContainer"
                                        style={{ alignItems: mainImageAlign }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            backgroundColor: "#EFF0FF",
                                            // backgroundColor: "transparent",

                                        }}>
                                            <Image
                                                style={[styles.image, { width: styles.basic.midImageWidth, height: styles.basic.midImageWidth }]}
                                                source={require("./img/multichannel_2.png")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={{ span: 6, order: 2 }} md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
                                    <View style={styles.airportIdentifierImageView}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#111019"

                                        }}>MULTICHANNEL</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#111019",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Interact with your customers where they are</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgb(108, 102, 132)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Regular",
                                            }}>
                                            Whether it's Email, Live Chat, Social Media or SMS,
                                            Addy AI can be seamlessly integrated across all platforms
                                            within minutes to meet your customers right where they are.

                                        </Text>
                                        {/* <br />
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>It even analyzes the intent behind incoming emails
                                            to offer you tailored reply options.</Text> */}
                                        <br />

                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            color: "rgb(75, 66, 173)",
                                            backgroundColor: "#EFF0FF",
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Request a demo
                                        </TouchableOpacity>
                                        {/* <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgba(255, 255, 255, 0.8)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Light",
                                            }}>Just click on a suggestion, and you'll have a complete,
                                            well-crafted email response in seconds</Text> */}
                                        <br />
                                    </View>
                                </Col>
                            </Row>
                        </div>

                        <div
                            className="container"
                            style={{
                                width: "100%",
                                // paddingLeft: styles.basic.blueBgPaddingLeft,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingBottom: (window.innerWidth < 500) ? 60 : 100,
                            }}>
                            <Row style={{ width: "100%", paddingTop: 10 }}>
                                <Col md="6">
                                    <View style={{ paddingTop: (window.innerWidth < 500) ? 20 : 40, paddingBottom: (window.innerWidth < 500) ? 0 : 60, width: "96%" }}>
                                        <Text style={{
                                            ...styles.headerTextNew,
                                            fontFamily: "SF-Pro-Display-Light",
                                            fontSize: "15px",
                                            color: "#8AB4F7",
                                            color: "#111019"

                                        }}>ALWAYS ON, ALWAYS THERE!</Text>
                                        <Text style={{
                                            ...styles.sectionSubtitle,
                                            color: "#111019",
                                            fontFamily: "SF-Pro-Display-Regular",
                                        }}>Give your customers 24/7 support with zero wait time</Text>
                                        <Text
                                            style={{
                                                fontSize: styles.basic.normalText,
                                                lineHeight: styles.basic.normalText * 1.7,
                                                fontWeight: "normal",
                                                color: "rgb(108, 102, 132)",
                                                textAlign: "left",
                                                fontFamily: "SF-Pro-Display-Regular",
                                            }}>
                                            Turn on the Background Response feature and your smart AI will take over for you.
                                            It will respond to all your customer inquiries 24/7, giving you more time to focus on growing your business.
                                        </Text>

                                        <br />

                                        <TouchableOpacity style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingRight: "25px",
                                            paddingLeft: "25px",
                                            borderRadius: "20px",
                                            gap: "15px",
                                            width: "fit-content",
                                            backgroundColor: "#EFF0FF",
                                            color: "#565ADC",
                                        }} onPress={() => this.sendButtonPressed()}>
                                            Request a demo
                                        </TouchableOpacity>

                                        <br />

                                    </View>
                                </Col>

                                <Col md="6">
                                    <div
                                        className="VerticalFlex"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            paddingTop: (window.innerWidth < 500) ? 0 : 40,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                        <div className="BorderRadiusImage" style={{
                                            minWidth: styles.basic.midImageWidth,
                                            backgroundColor: "#EFF0FF",
                                            // backgroundColor: "transparent",
                                        }}>
                                            <Image
                                                style={[styles.image, {
                                                    width: styles.basic.midImageWidth,
                                                    height: styles.basic.midImageWidth,
                                                    borderRadius: "25px"
                                                }]}
                                                source={require("./img/shoe_convo.gif")}
                                                resizeMode="cover"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    {/* <div
                        className="container-fluid"
                        style={{
                            width: styles.basic.demoDivWidth,
                            // paddingLeft: styles.basic.blueBgPaddingLeft,
                            justifyContent: "center",
                            paddingBottom: 20,
                            paddingTop: "70px",
                            backgroundColor: "rgba(211, 211, 211, 0.15)",
                            // backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backgroundColor: "#2B1C50",
                            // backgroundColor: "#110F19",
                            borderRadius: "50px"
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText
                        }}><mark
                            style={{
                                backgroundColor: "transparent",
                                // color: "#745DDE",
                                color: "#F1F0F3",
                            }}>Resolve complex inquiries in seconds</mark></p>
                        <br />
                        <p style={styles.headerSubtitle}><mark style={{
                            backgroundColor: "transparent",
                            color: "#BBB6C6",
                        }}>
                            Companies using Addy AI respond to their customers 60% faster
                        </mark></p>

                        <div style={{ height: "100px" }}> </div>

                        <Demo />

                    </div> */}

                    {/* <div
                        className="AboutContainer"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 60,
                            marginTop: 150,
                            paddingBottom: 20,
                            backgroundColor: "rgba(211, 211, 211, 0.15)",
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText
                        }}>
                            What our customers say</p>
                        <br />
                        <div className="row" style={{ marginTop: "60px", padding: 40 }}>



                            <div className="col-lg-6 justify-content-center">




                                <div
                                    className="VerticalFlex OppositeCenter"
                                    style={{
                                        // width: "90%",
                                        borderRadius: "20px",
                                        // backgroundColor: "#EBF2FF",
                                        backgroundColor: "white",
                                        // backgroundColor: "#EBEEFF",
                                        paddingTop: 50,
                                        paddingBottom: 50,
                                        paddingRight: 30,
                                        paddingLeft: 30,
                                        border: "0.5px solid rgba(0, 0, 0, 0.2)"
                                    }}>
                                    <Youtube
                                        opts={{
                                            playerVars: {
                                                autoplay: 0,
                                                loop: 1,
                                            },
                                            width: styles.basic.midImageWidth,
                                            height: styles.basic.midImageWidth,
                                        }}
                                        videoId="l5xExQlTE1U"
                                    />

                                  

                                    <p className="TestimonialDescription">
                                        "Addy AI makes dealing with repeated customer emails a lot easier and helps save time"
                                    </p>
                                    <p className="TestimonialName">Carl</p>
                                    <p className="TestimonialTitle">CEO, Vinciage Consulting</p>
                                </div>

                            </div>

                            <div className="col-lg-6">

                                <div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="VerticalFlex CenterContentsCompletely" style={{
                                paddingTop: 80,
                                paddingBottom: 10
                            }}>
                                <TouchableOpacity className="SubmitButton" onPress={() => this.sendButtonPressed()}>
                                    <p className="SubmitText">Request a Demo</p>
                                </TouchableOpacity>
                            </div>

                        </div>

                    </div> */}


                    <div
                        className="AboutContainer container"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            // justifyContent: "center",
                            paddingTop: 60,
                            marginTop: 150,
                            paddingBottom: 20,
                            backgroundColor: "#2B1C50",
                            borderRadius: "25px"
                            // backgroundColor: "#F9FAFF",
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "#FFFFFF",
                            marginBottom: "15px"
                        }}>
                            What people say about Addy AI</p>
                        <p style={{
                            ...styles.headerSubtitle,
                            color: "rgba(255, 255, 255, 0.8)"
                        }}>Addy has helped resolve over <mark style={{
                            backgroundColor: "transparent",
                            fontWeight: "bold",
                            color: "rgba(255, 255, 255, 0.8)"
                        }}>100,000</mark> customer issues</p>
                        <br />
                        <div className="row" style={{ marginTop: "30px", padding: 0 }}>

                            <div className="col-lg-4 justify-content-center">

                                <div>
                                    <div style={{ height: "300px" }}>
                                        <iframe id="-Nci3qKs832jDfZReW3_" src="https://embed-v2.testimonial.to/share/-Nci3qKs832jDfZReW3_" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>

                                    {/* <div style={{ height: "300px" }}>
                                        <iframe id="-Nci3gsVHvQUn9GHJzBq" src="https://embed-v2.testimonial.to/share/-Nci3gsVHvQUn9GHJzBq" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div> */}

                                </div>

                            </div>


                            <div className="col-lg-4 justify-content-center">

                                <div>
                                    {/* <div style={{ height: "350px" }}>
                                        <iframe id="-Nci485hCQyeelwznqxu" src="https://embed-v2.testimonial.to/share/-Nci485hCQyeelwznqxu" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div> */}
                                    <div style={{ height: "360px" }}>
                                        <iframe id="-N_jXYmjHEIXtGp19-4o" src="https://embed-v2.testimonial.to/share/-N_jXYmjHEIXtGp19-4o" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 justify-content-center">
                                <div>
                                    <div style={{ height: "300px", }}>
                                        <iframe id="-N_jglbWrXdObHILrny4" src="https://embed-v2.testimonial.to/share/-N_jglbWrXdObHILrny4" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div>
                                    {/* <div style={{ height: "300px" }}>
                                        <iframe id="-Nci61gJFakb1B4GSr7r" src="https://embed-v2.testimonial.to/share/-Nci61gJFakb1B4GSr7r" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
                                    </div> */}
                                </div>
                            </div>

                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}>
                            <TouchableOpacity onPress={() => this.sendButtonPressed()}>
                                <p className="SubmitText">Request a Demo</p>
                            </TouchableOpacity>

                        </div>

                    </div>


                    {/* <div className="AboutContainer container-fluid"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 80,
                            backgroundColor: "#202125",
                            backgroundColor: "rgba(255, 255, 255, 01)",
                            // backgroundColor: "#2B1C50",

                            paddingTop: "70px"
                        }}>

                        <p style={{
                            ...styles.headerTextDark,
                            fontSize: styles.basic.titleText - 9,
                            fontFamily: "SF-Pro-Display-Regular",
                            color: "rgba(0, 0, 0, 0.8)",
                            fontWeight: "normal",
                            marginBottom: "15px"
                        }}>
                            Proudly backed by</p>

                        <div className="VerticalFlex CenterContentsCompletely" style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            width: "100%",
                            gap: "20px",
                        }}>

                            <div style={{
                                display: "flex",
                                gap: 30,
                                flexWrap: "wrap"
                            }}>
                                <img src="https://i.imgur.com/v33lYtV.png"
                                    width="360px" height="auto" />

                                <img src="https://i.imgur.com/AR2u6gb.png"
                                    width="360px" height="auto" />
                            </div>



                            <p style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontFamily: "SF-Pro-Display-Light",
                                fontSize: "22px",
                                width: "360px",
                                textAlign: "left"

                            }}>
                                And notable Angels - Merline Saintil
                            </p>

                        </div>

                    </div> */}




                    <div
                        className="HowItWorksContainer"
                        style={
                            {
                                paddingBottom: 100,
                                marginTop: (window.innerWidth < 500) ? "60px" : "90px"
                            }
                        }>
                        <div className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv"
                            style={{
                                marginTop: "10px",
                                marginBottom: (window.innerWidth < 500) ? "30px" : "0px"
                            }}>


                            <p style={{
                                ...styles.headerTextDark,
                                fontSize: styles.basic.titleText * 0.9,
                                fontFamily: "SF-Pro-Display-Regular",
                                color: "#2B1C50",
                                marginBottom: "10px"
                            }}>
                                See Addy AI in action</p>

                            <p style={styles.headerSubtitle}>Discover how AI-powered customer support can help you save 3+ hours a day</p>
                        </div>

                        <InlineWidget className="hide-scrollbar" styles={{
                            width: '100%',
                            // minWidth: '400px',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            height: "650px",
                            overflowX: "scroll",
                        }} url="https://calendly.com/michael-addy-ai/addy-ai-demo" />

                    </div>



                    {/* <div className="SecurityContainer" style={{
                        width: '100%',
                        justifyContent: 'center', paddingTop: 70, paddingBottom: 80
                    }}>

                        <View style={{ alignContent: 'center', justifyContent: "center" }}>

                            <div className="VerticalFlex CenterContents CenterOppositeDirectionToo DownloadBtnDiv"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    width: "100%",
                                    marginBottom: 80,
                                }}>
                                <br />
                                <Text style={styles.headerTextWhite}>
                                    Reviews
                                </Text>

                            </div>

                            <div style={{ width: "100%" }}>
                                <Reviews width={midImageHeight} />
                            </div> */}

                    {/* <Col md="6" >
                  <View style={{paddingTop: 90, paddingBottom: 60, width: '100%',}}>
                    <Text style={styles.headerTextWhite}>
                        Security First
                    </Text>
                      <br/>

                      <div className="HorizontalFlex" style={{paddingRight: 40}}>
                        <br/>
                        <a>
                            <FaCheckCircle
                                color="6D53AF"
                                size="2.5em"
                                style={{marginRight: 15}}
                            />
                        </a>
                        <br/>

                        <Text style={{fontSize: 25, fontWeight: 'normal', color: '#6C6684',
                            textAlign: "left", fontFamily: 'SF-Pro-Display-Regular'}}>
                            [Coming Soon] We're building
                            enterprise-grade encryption to secure your data in transit.
                        </Text>
                    
                    </div>
                    <br/>
                    <div className="HorizontalFlex" style={{paddingRight: 40}}>
                        <br/>
                        <a>
                            <FaCheckCircle
                                color="6D53AF"
                                size="2.5em"
                                style={{marginRight: 15}}
                            />
                        </a>
                        <br/>

                        <Text style={{fontSize: 25, fontWeight: 'normal', color: '#6C6684',
                            textAlign: "left", fontFamily: 'SF-Pro-Display-Regular'}}>
                            All email addresses in your email thread are stripped locally on your machine before
                            processing.
                        </Text>
                    
                    </div>
                    <br/>
                    <div className="HorizontalFlex" style={{paddingRight: 40}}>
                        <br/>
                        <a>
                            <FaCheckCircle
                                color="6D53AF"
                                size="2.5em"
                                style={{marginRight: 15}}
                            />
                        </a>
                        <br/>

                        <Text style={{fontSize: 25, fontWeight: 'normal', color: '#6C6684',
                            textAlign: "left", fontFamily: 'SF-Pro-Display-Regular'}}>
                            Addy AI does not store your emails or associate your email address 
                            with the email conversation.
                        </Text>
                    
                    </div>
                    <br/>
                    <div className="HorizontalFlex" style={{paddingRight: 40}}>
                        <br/>
                        <a>
                            <FaCheckCircle
                                color="6D53AF"
                                size="2.5em"
                                style={{marginRight: 15}}
                            />
                        </a>
                        <br/>

                        <Text style={{fontSize: 25, fontWeight: 'normal', color: '#6C6684',
                            textAlign: "left", fontFamily: 'SF-Pro-Display-Regular'}}>
                            Addy AI does not read your emails in the background. Email processing happens 
                            only when you click the "Write email" button.
                        </Text>
                    
                    </div>
                    
                  </View>

                </Col> */}

                    {/* <Col md="6">
                  <div className="VerticalFlex"
                    style={{width: "100%", height: "100%", paddingTop: 60,
                        justifyContent: "center", alignItems: "center"}}>
                        <Image
                            style={{width: midImageHeight, height: midImageHeight}}
                            source={require('./img/security_first.gif')}
                            resizeMode='contain'
                        /> 
                  </div>
                </Col> */}

                    {/* </View>

                    </div> */}

                    <Footer backgroundColor="#EFF0FF" textColor="#3D2E7C" iconColor="#3D2E7C" />
                </div>
            );
        } else {
            return null;
        }
    }

    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <Modal style={styles.mainConfirmationView}>
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            padding: 5,
                        }}>
                        <Text
                            style={{
                                fontWeight: "700",
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 90,
                                marginTop: 50,
                            }}>
                            The Future of Email is Here
                        </Text>
                        <Image style={styles.checkImage} source={require("./img/check_circle.svg")} resizeMode="cover" />
                        <Text
                            style={{
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontSize: confirmNameText,
                                textAlign: "center",
                                wordWrap: "break-word",
                                fontFamily: "SF-Pro-Display-Bold",
                                color: "#2B1C50",
                                marginBottom: 18,
                                marginTop: 30,
                            }}>
                            Thank you!!
                        </Text>
                        <Text style={styles.youSigned}>Your email was successfully submitted.</Text>
                        <Text style={styles.wewill}>We will add you to the waitlist</Text>
                        <View style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></View>
                        {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                        <div className="AppLogo" onClick={this.onAppLogoClick}>
                            <p className="AppTitleText">Addy AI</p>
                        </div>
                        <br />
                        <br />
                    </View>

                    {/* <Image
                      style={styles.confirmationImage}
                      source={this.state.imageSrc || require('./img/frame2.png')}
                      resizeMode='cover'
                    /> */}
                </Modal>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <div style={{ height: "100vh" }}>
                {this.Showconfirmation()}
                {this.MainPage()}
            </div>
        );
    }
}

export default function (props) {
    // const navigation = useNavigation();
    return <App />;
}
