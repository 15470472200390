import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyCDBO1NWURhFlBZpd1Bxc2pxUPhV8k8LMQ",
  authDomain: "hey-addy-chatgpt.firebaseapp.com",
  databaseURL: "https://hey-addy-chatgpt-default-rtdb.firebaseio.com",
  projectId: "hey-addy-chatgpt",
  storageBucket: "hey-addy-chatgpt.appspot.com",
  messagingSenderId: "284266859441",
  appId: "1:284266859441:web:b39fcb40f56d55bb410b90",
  measurementId: "G-6WDB7TFWDL",
};
const fb = firebase.initializeApp(config);
const app = initializeApp(config);

const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth(app);
const firestore = getFirestore(app);


export { fb, app, analytics, database, auth, firestore };
