// App.js - WEB
import React, { Component } from "react";
import { StyleSheet, Dimensions, Text, Image } from "react-native";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuidv4 } from 'uuid';
import Header from ".././header";
import Footer from "../footer";
import { updateDimensions, styles } from "../styles";
import { ref, set, update, get } from "firebase/database";
import { database } from "../firebase.js";
import FeedbackForm from "./uninstallFeedbackForm.js";

const visitId = uuidv4();

class UnInstalled extends Component {
    constructor(props) {
        super(props);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const visitSource = urlParams.get("src");

        let formattedVisitSource = visitSource == null ? "unknown" : visitSource;
        formattedVisitSource = formattedVisitSource.replaceAll(/\.|#|\$|[|]/g, ""); // replace illegal firebase path characters

        this.state = {
            uid: formattedVisitSource,
            showConfirmation: false,
        };
        // Log uninstall
        this.logUninstall(formattedVisitSource);
    }

    updateDimensions() { }

    componentDidMount() {
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions.bind(this));
        // Let the team know someone is uninstalling
        // TODO: Consider adding this back
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    logUninstall(source) {
        if (window.location.hostname.includes("localhost")) return;

        const date = Date.now();

        const d = new Date(date);

        const day = d.getDate();
        const month = d.getMonth() + 1; // add 1 because getMonth() returns zero-based values
        const year = d.getFullYear();

        const uninstallDateString = `${month}-${day}-${year}`;

        var visitRef = ref(database, `Analytics/UninstallLog/${source}/${visitId}`);

        set(visitRef, {
            ddate: date,
            dateString: uninstallDateString,
            id: visitId,
            uid: source,
        }).then(() => {
            // Update the count
            var countRef = ref(database, `Analytics/UninstallLogCount/${uninstallDateString}`);
            get(countRef).then((snapshot) => {
                var count = snapshot.val() == null || !snapshot.val().count ? 0 : snapshot.val().count;
                var num = Number(count);

                if (count != null) {
                    let newCount = num + 1;
                    // update count
                    return update(countRef, {
                        count: newCount.toString(),
                    }).then(() => {
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }).catch((error) => {
            console.error(error);
        });

    }

    submitFeedback = (feedback) => {
        const uid = this.state.uid;

        // fetch
        const API_URL = "https://us-central1-hey-addy-chatgpt.cloudfunctions.net/api/user/feedback";
        fetch(API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback: feedback,
                uid: uid
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    // Add the success responses
                    this.setState({
                        showConfirmation: true,
                    });
                    return;
                }
                // Not successful
                alert("Sorry something went wrong. Please try again");
            })
            .catch((error) => {
                alert("Sorry something went wrong. Please try again");
            });

    }


    Showconfirmation() {
        if (this.state.showConfirmation == true) {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5,
                    }}>
                    <h2
                        style={{
                            fontWeight: "700",
                            fontStyle: "normal",
                            textAlign: "center",
                            wordWrap: "break-word",
                            fontFamily: "SF-Pro-Display-Bold",
                            color: "#2B1C50",
                            fontSize: "25px",
                            marginBottom: 30,
                            marginTop: 10,
                        }}>
                        Thank you for your feedback
                    </h2>
                    <img src="https://i.imgur.com/XDc1kBB.png" width="100" height="100" />
                    <Text
                        style={{
                            fontStyle: "normal",
                            textAlign: "center",
                            wordWrap: "break-word",
                            color: "#2B1C50",
                            marginBottom: 18,
                            marginTop: 30,
                        }}>
                        Uninstalled by mistake? <a style={{ textDecoration: "underline" }} target="_blank" href="https://chromewebstore.google.com/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn">Reinstall extension</a>
                    </Text>

                    <div style={{ borderTopColor: "#E8E8E8", borderTopWidth: 1, width: "40%", opacity: 0.7 }}></div>
                    {/* <Text style={styles.ifyou}> If you did not receive an email, click <a onClick={() => this.sendConfirmation(email)} href="" color="blue">resend email</a></Text> */}
                    <div className="AppLogo" onClick={() => window.open("https://addy.so", "_blank")}>
                        <p className="AppTitleText">Addy AI</p>
                    </div>
                    <br />
                    <br />
                </div>

            );
        } else {
            return null;
        }
    }

    MainPage() {
        return (
            <div className="AboutUsContainer" style={{ background: "#F1F4F9" }}>
                <div className="AppLogo" style={{
                    justifyContent: "flex-start",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "30px",
                }} onClick={() => window.open("https://addy.so", "_blank")}>

                    <p className="AppTitleText">Addy</p>
                </div>
                {/* How */}
                <div
                    className="row AboutContainer"
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: 10,
                        paddingRight: 10,
                        justifyContent: "center",
                        paddingTop: 50,
                        paddingBottom: "50px"
                    }}>


                    <div className="col-md-6">

                        <div style={{
                            // padding:"100% 0 0 0",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p style={styles.headerTextWhite3}>Please fill our feedback form</p>
                            <iframe src="https://player.vimeo.com/video/849995796?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{
                                // position:"absolute",
                                // top: "0",
                                // left: "0",
                                width: "400px",
                                height: "400px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                borderRadius: "15px",

                                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                // padding: "25px",
                                // backgroundColor: "white"
                            }} title="">
                            </iframe>
                            <a style={{
                                color: "#1D344B",
                                width: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                border: "1px solid #1D344B",
                                textDecoration: "none",
                                borderRadius: "10px",
                                fontSize: "15px",
                                fontWeight: "bold",
                            }} href="https://chrome.google.com/webstore/detail/addy-ai-chatgpt-email-ass/gldadickgmgciakdljkcpbdepehlilfn" target="_blank">Reinstall extension</a>


                            <div style={{
                                width: '400px',
                                minWidth: "fit-content",
                                marginTop: "30px",
                            }}>
                            
                            <p style={{
                                ...styles.headerTextWhite3,
                                fontSize: 20,
                                marginTop: "10px",
                                textAlign: "left",
                                width: "100%"
                            }}>
                                Get the most out of Addy
                            </p>

                            </div>

                            <div style={{
                                width: '400px',
                                minWidth: "fit-content",
                                marginTop: "15px",
                                borderRadius: "20px",
                                background: "rgba(0, 0, 0, 0.08)",
                                padding: "10px"
                            }}>
                                <p style={{
                                    ...styles.headerTextWhite3,
                                    fontSize: 17,
                                    marginTop: "10px"
                                }}>
                                    You can train Addy to sound like you
                                </p>
                                <p style={{
                                    color: "#1D344B",
                                    fontSize: "14px",
                                    width: "400px",
                                }}>
                                    Want Addy to generate better email responses that sound like you? Explore our <a href="./pricing?ref=pricing-uninstall" style={{ color: "#1D344B" }}>Paid Plans</a> where you can train Addy to mimic your writing style. You can also train Addy on your website and PDF files.
                                </p>

                            </div>


                            <div style={{
                                width: '400px',
                                minWidth: "fit-content",
                                marginTop: "15px",
                                borderRadius: "20px",
                                background: "rgba(0, 0, 0, 0.08)",
                                padding: "10px"
                            }}>
                                <p style={{
                                    ...styles.headerTextWhite3,
                                    fontSize: 17,
                                    marginTop: "10px"
                                }}>
                                    We value your privacy
                                </p>
                                <p style={{
                                    color: "#1D344B",
                                    fontSize: "14px",
                                    width: "400px",
                                }}>
                                    We use strict security policies to safeguard your
                                    information, and our privacy-first approach ensures
                                    you're in full control of your data. We don't sell,
                                    share, or do anything malicious with your data.
                                </p>

                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <FeedbackForm submit={this.submitFeedback} uid={this.state.uid} />
                    </div>

                </div>
                {/* <Footer /> */}
            </div>
        );
    }

    render() {
        return <div style={{ height: "100vh" }}>{
            this.state.showConfirmation ? this.Showconfirmation() :
                this.MainPage()}</div>;
    }
}

export default UnInstalled;
