// index.js - WEB
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Tos from "./tos";
import PrivacyPolicy from "./pp";
import Installed from "./onboarding/installed";
import Updated from "./onboarding/updated";
import Pricing from "./pricing/pricing";
import IntroducingPricing from "./pricing/introducing-pricing";
import PaymentSuccessful from "./pricing/successful-payment";
import UpgradeInstructions from "./pricing/how-to-upgrade";
import UnInstalled from "./onboarding/uninstalled";
import DeleteAccount from "./delete-account/delete-account";
import AcceptBusinessInvite from "./onboarding/acceptBusinessInvite";
import Poll from "./polls/poll";
import AcceptWorkshopInvite from "./workshops/acceptInvite";
import LangDrive from "./langDrive";
import EmailAssistant from "./emailAssistant";
import AboutUs from "./aboutUs";
import InvestorInfo from "./investorInfo";
import TrainingInstructions from "./onboarding/howToTrain";
import Stealth from "./stealth";
import AboutUsStealth from "./aboutUsStealth";
import TikTok from "./campaign/tiktok";
import HelpCenter from "./helpCenter/helpCenter";
import ContactUs from "./contactUs/contactUs";
import TryEllieAlternative from "./alternatives/tryEllieAlternative";
import AboutUsTeam from "./aboutUsTeam";
import NewEmail from "./onboarding/newEmail";

const root = createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<EmailAssistant />} />

            <Route path="/pricing" element={<Pricing />} />
            <Route path="/subscribed" element={<PaymentSuccessful />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/accept-business-invite" element={<AcceptBusinessInvite />} />

            {/* Stealth */}
            {/* <Route path="/" element={<Stealth />} /> */}

            {/* <Route path="/customer-support" element={<App/>} /> */}
            {/* <Route path="/termsofservice" element={<Tos />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/chrome-installed" element={<Installed />} />
            <Route path="/chrome-updated" element={<Updated />} />
            
            <Route path="/introducing-pricing" element={<IntroducingPricing />} />
            
            <Route path="/how-to-upgrade" element={<UpgradeInstructions />} />
            <Route path="/how-to-train" element={<TrainingInstructions />} />
            <Route path="/chrome-uninstalled" element={<UnInstalled />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/accept-business-invite" element={<AcceptBusinessInvite />} />
            <Route path="/poll" element={<Poll />} />
            <Route path="/workshop/register" element={<AcceptWorkshopInvite />} />
            <Route path="/products/langdrive" element={<LangDrive />} /> */}
            {/* <Route path="/email" element={<EmailAssistant />} /> */}
            {/* <Route path="/about-us" element={<AboutUsStealth />} />
            <Route path="/investor-info" element={<InvestorInfo />} /> */}
            {/* <Route path="/stealth" element={<Stealth />} /> */}
            {/* <Route path="/about" element={<AboutUsStealth />} />
            <Route path="/team" element={<AboutUsTeam />} /> */}
            {/* <Route path="/tiktok" element={<TikTok />} /> */}
            {/* <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/contactus" element={<ContactUs />} /> */}
            {/* Affiliates */}
            {/* <Route path="/tim" element={<EmailAssistant />} /> 
            <Route path="/valerio" element={<EmailAssistant />} /> 
            <Route path="/ali" element={<EmailAssistant />} /> */}
            

            {/* <Route path="/newemail" element={<NewEmail />} /> */}

            {/* Alternatives */}
            {/* <Route path="/compare/try-ellie-ellie-ai-alternative" element={<TryEllieAlternative />} /> */}

        </Routes>
    </BrowserRouter>
);
